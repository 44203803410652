import React from "react";
import { useLocation } from "react-router-dom";
import NavProfile from "../ProfilePages/NavProfile";
import ProfileContent from "../ProfilePages/ProfileContent";
import { useAuth } from "../../contexts/AuthContext";

const Profile = () => {
  const { user } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId");
  const currentPage = location.pathname.split("/").pop();

  return (
    <div className="row" style={{ maxWidth: "980px", marginLeft: "11%" }}>
      {(!userId || userId === user?.id) && <NavProfile userId={user?.id} />}
      <ProfileContent currentPage={currentPage} userId={userId} />
    </div>
  );
};

export default Profile;
