import React, { useCallback, useEffect, useMemo, useState } from "react";
import useFetchData from "../../../hooks/useFetchData";
import TextBlock from "../../UI/TextBlock";
import { Button, FormFeedback, Input } from "reactstrap";
import DataTable from "../../UI/DataTable";
import { format, addDays } from "date-fns";
import { uk } from "date-fns/locale";
import DefaultSelect from "../../UI/DefaultSelect";
import RequestType from "../../../models/RequestType";
import { ReactComponent as IconEye } from "../../../assets/icons/eye-line.svg";
import { ReactComponent as IconEyeOff } from "../../../assets/icons/eye-off-line.svg";
import styles from "./Users.module.css";
import { useToast } from "../../../contexts/ToastContext";
import UniversalModalWindow from "../../UI/UniversalModalWindow";
import Loading from "../../UI/Loading";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [banDays, setBanDays] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [error, setError] = useState("");
  const { showToast } = useToast();
  const { fetchData } = useFetchData("Admin/GetUsers");
  const { fetchData: getRoles } = useFetchData("Admin/GetRoles");
  const { fetchData: changeRole } = useFetchData(
    "Admin/ChangeRole",
    RequestType.POST
  );
  const { fetchData: bannedUser } = useFetchData(
    "Admin/BannedUser",
    RequestType.POST
  );
  const { fetchData: unbannedUser } = useFetchData(
    "Admin/UnbannedUser",
    RequestType.POST
  );

  useEffect(() => {
    fetchData()
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [fetchData]);

  useEffect(() => {
    getRoles()
      .then((data) => {
        const formattedRoles = data.map((role) => ({
          value: role.id,
          label: role.name,
        }));
        setRoles(formattedRoles);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [getRoles]);

  const handleBannedClick = useCallback(
    (userId) => {
      setSelectedUserId(userId);
      setModalOpen(true);
    },
    [setSelectedUserId]
  );

  const handleBanned = useCallback(() => {
    if (selectedUserId !== null && banDays) {
      const days = parseInt(banDays, 10);
      const bannedUntilDate = addDays(new Date(), days).toISOString();
      bannedUser({ userId: selectedUserId, bannedUntil: bannedUntilDate })
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user.id === selectedUserId
                  ? { ...user, bannedUntil: bannedUntilDate }
                  : user
              )
            );
            setModalOpen(false);
            setBanDays("");
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to fetch data:", err);
        });
    }
  }, [bannedUser, selectedUserId, banDays, showToast]);

  const handleUnbanned = useCallback(
    (userId) => {
      unbannedUser(userId)
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user.id === userId ? { ...user, bannedUntil: null } : user
              )
            );
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to fetch data:", err);
        });
    },
    [unbannedUser, showToast]
  );

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy", { locale: uk });
  }, []);

  const handleRoleChange = useCallback(
    (selectedOption, userId) => {
      changeRole({ userId: userId, newRoleId: selectedOption.value })
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setUsers((prevUsers) =>
              prevUsers.map((user) =>
                user.id === userId
                  ? { ...user, role: selectedOption.value }
                  : user
              )
            );
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to add brand:", err);
        });
    },
    [changeRole, showToast]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[1-9][0-9]*$/.test(value) || value === "") {
      setBanDays(value);
      setError("");
    } else {
      setError("Введіть позитивне число, яке не починається з нуля.");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (_, index) => index + 1,
      },
      {
        Header: "Ім'я",
        accessor: "name",
      },
      {
        Header: "Прізвище",
        accessor: "surname",
      },
      {
        Header: "Пошта",
        accessor: "email",
      },
      {
        Header: "Логін",
        accessor: "login",
      },
      {
        Header: "Дата реєстрації",
        accessor: "registrationDate",
        Cell: ({ row }) => (
          <span>{formatDate(row.original.registrationDate)}</span>
        ),
      },
      {
        Header: "Заблокований до",
        accessor: "bannedUntil",
        Cell: ({ row }) => (
          <span>
            {row.original.bannedUntil
              ? formatDate(row.original.bannedUntil)
              : "Не заблокований"}
          </span>
        ),
      },
      {
        Header: "Роль",
        accessor: "role",
        disableSort: true,
        Cell: ({ row }) => {
          const currentRole = roles.find(
            (role) => role.value === row.original.role
          );
          return (
            <DefaultSelect
              options={roles}
              value={currentRole || null}
              onChange={(selectedOption) =>
                handleRoleChange(selectedOption, row.original.id)
              }
            />
          );
        },
      },
      {
        Header: "Відображення",
        accessor: "banned",
        disableSort: true,
        Cell: ({ row }) => (
          <div className={styles.actionButton}>
            {row.original.bannedUntil ? (
              <Button
                color="success"
                onClick={() => handleUnbanned(row.original.id)}
              >
                <IconEye className={styles.icon} />
                Розблокувати
              </Button>
            ) : (
              <Button
                color="danger"
                onClick={() => handleBannedClick(row.original.id)}
              >
                <IconEyeOff className={styles.icon} />
                Заблокувати
              </Button>
            )}
          </div>
        ),
      },
    ],
    [handleUnbanned, handleBannedClick, handleRoleChange, formatDate, roles]
  );

  const inputChildren = () => (
    <div>
      <Input
        type="text"
        value={banDays}
        onChange={handleInputChange}
        placeholder="Кількість днів"
        invalid={!!error}
      />
      <FormFeedback>{error}</FormFeedback>{" "}
    </div>
  );

  const footerChildren = () => (
    <>
      <Button color="danger" onClick={handleBanned}>
        Заблокувати
      </Button>{" "}
      <Button color="secondary" onClick={() => setModalOpen(false)}>
        Закрити
      </Button>
    </>
  );

  if (
    (!users || Object.keys(users).length === 0) &&
    (!roles || Object.keys(roles).length === 0)
  ) {
    return <Loading />;
  }

  return (
    <TextBlock color="#fff" className="cardText">
      <h2>
        <strong>Користувачі</strong>
      </h2>
      <DataTable data={users} columns={columns} />
      <UniversalModalWindow
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        header={"Блокування користувача"}
        children={inputChildren()}
        footerChildren={footerChildren()}
      />
    </TextBlock>
  );
};

export default Users;
