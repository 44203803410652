import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../UI/TextInput";
import { useApi } from "../../../contexts/ApiContext";
import AuthButton from "../../UI/AuthButton";
import { useAuth } from "../../../contexts/AuthContext";
import Identification from "../../UI/Identification";
import styles from "../../UI/Identification.module.css";

const Authorize = () => {
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });
  const [apiUrl, setApiUrl] = useState("");
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const apiUrlFromContext = useApi();
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setApiUrl(apiUrlFromContext);
  }, [apiUrlFromContext]);

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.userName) {
      errors.userName = "Ім'я користувача не може бути пустим.";
    }
    if (!formData.password) {
      errors.password = "Пароль не може бути пустим.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});

    try {
      const result = await login(formData, apiUrl);
      if (!result.success) {
        setError(result.message);
      } else {
        const from = location.state?.from?.pathname || "/";
        navigate(from);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const openRegistration = () => {
    navigate("/registration");
  };

  return (
    <Identification
      header="Авторизація"
      contextText="Потрібно створити аккаунт?"
      linkText="Створити"
      clickEvent={openRegistration}
    >
      {error && (
        <div
          className={styles.errorMessage}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
      {Object.keys(validationErrors).length > 0 && (
        <div className={styles.errorMessage}>
          {Object.values(validationErrors).map((err, index) => (
            <div key={index}>{err}</div>
          ))}
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <TextInput
            type="text"
            id="userName"
            placeholder="Ім'я користувача"
            value={formData.userName}
            onChange={(value) => handleChange("userName", value)}
            error={validationErrors.userName}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            type="password"
            id="password"
            placeholder="Пароль"
            value={formData.password}
            isPassword={true}
            onChange={(value) => handleChange("password", value)}
            error={validationErrors.password}
          />
        </FormGroup>

        <AuthButton type="submit">Вхід</AuthButton>
        <Link
          style={{ color: "var(--primary-blue)", fontSize: "14px" }}
          to="/forgotpassword"
        >
          Пароль втрачено?
        </Link>
      </Form>
    </Identification>
  );
};

export default Authorize;
