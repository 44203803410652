import React, { useEffect, useState } from "react";
import useFetchData from "../../../hooks/useFetchData";
import { useParams } from "react-router-dom";
import UserFilesForm from "./UserFilesForm";
import ImagePreviewModal from "../../UI/ImagePreviewModal";
import TextBlock from "../../UI/TextBlock";
import DefaultSelect from "../../UI/DefaultSelect";
import RequestType from "../../../models/RequestType";
import { useToast } from "../../../contexts/ToastContext";
import {
  transformOption,
  transformSelectOption,
  prepareModerationData,
} from "../../../utils/dataTransform";
import styles from "./UserFilesDetails.module.css";

const UserFilesDetails = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [user, setUser] = useState([]);
  const [selectStatusFile, setSelectStatusFile] = useState([]);
  const [selectStatusVerifFiles, setSelectStatusVerifFiles] = useState([]);
  const [passportStatus, setPassportStatus] = useState(null);
  const [ipnStatus, setIpnStatus] = useState(null);
  const [filesStatus, setFilesStatus] = useState([]);
  const [filesWithPurpose4, setFilesWithPurpose4] = useState([]);
  const [filesWithPurpose5, setFilesWithPurpose5] = useState([]);

  const { showToast } = useToast();

  const { fetchData } = useFetchData(`Moderator/GetModerationUserDetail/${id}`);
  const { fetchData: getSelectStatus } = useFetchData(
    "Moderator/GetSelectStatus"
  );
  const { fetchData: submitFormModeration } = useFetchData(
    `Moderator/ModerationUserFiles`,
    RequestType.POST
  );

  const loadFiles = () => {
    fetchData()
      .then((data) => {
        setUser(data);
        setPassportStatus(transformOption(data.user.passportStatus));
        setIpnStatus(transformOption(data.user.ipnStatus));
        setFilesWithPurpose4(
          data?.mediaObject?.result.filter(
            (file) => file?.personalMedObj?.filePurpose === 4
          )
        );
        setFilesWithPurpose5(
          data?.mediaObject?.result.filter(
            (file) => file?.personalMedObj?.filePurpose === 5
          )
        );
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  };

  useEffect(() => {
    if (id) {
      loadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getSelectStatus()
      .then((data) => {
        setSelectStatusFile(transformSelectOption(data.statusPersonalFile));
        setSelectStatusVerifFiles(
          transformSelectOption(data.statusVerificationFile)
        );
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [getSelectStatus]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handlePassportStatusChange = (selectedOption) => {
    setPassportStatus(selectedOption);
  };

  const handleIpnStatusChange = (selectedOption) => {
    setIpnStatus(selectedOption);
  };

  const handleFileSave = (fileId, status, rejectReason) => {
    const updatedFilesStatus = [...filesStatus];
    const existingFileIndex = updatedFilesStatus.findIndex(
      (file) => file.fileId === fileId
    );
    if (existingFileIndex >= 0) {
      updatedFilesStatus[existingFileIndex] = { fileId, status, rejectReason };
    } else {
      updatedFilesStatus.push({ fileId, status, rejectReason });
    }
    setFilesStatus(updatedFilesStatus);
  };

  const handleSubmit = (purpose) => {
    const moderationData = prepareModerationData(
      filesStatus,
      user.user,
      passportStatus,
      purpose
    );

    submitFormModeration(moderationData)
      .then((data) => {
        if (data.isSuccess) {
          showToast("Успіх", data.message, "success");
          loadFiles();
        } else {
          showToast("Помилка", data.message, "danger");
        }
      })
      .catch((err) => {
        showToast("Помилка", err, "danger");
      });
  };

  return (
    <>
      <TextBlock color="#fff" className="cardText">
        <h2>{`Файли користувача "${user?.user?.firstName} ${user?.user?.surname}"`}</h2>
      </TextBlock>
      <TextBlock color="#dbf6fa" className="cardText">
        <h3>Файли паспорту</h3>
        <div className="col-xl-12">
          {Array.isArray(filesWithPurpose4) &&
          filesWithPurpose4.length !== 0 ? (
            <>
              <div className={styles.cardGrid}>
                {filesWithPurpose4.map((file) => (
                  <div
                    className={styles.fileCard}
                    key={file.personalMedObj.mediaObjectID}
                  >
                    <UserFilesForm
                      file={file}
                      onSave={handleFileSave}
                      setImgModal={setSelectedImage}
                      toggleModal={toggleModal}
                      statusFile={selectStatusFile}
                      isEditable={file?.status?.key !== 1}
                    />
                  </div>
                ))}
              </div>
              <div className="d-flex">
                <div className={styles.select}>
                  <DefaultSelect
                    key="Passport"
                    options={selectStatusVerifFiles}
                    value={passportStatus}
                    onChange={handlePassportStatusChange}
                  />
                </div>
                <button
                  className={styles.btnPrimaryBlue}
                  onClick={() => handleSubmit(4)}
                >
                  Відправити
                </button>
              </div>
            </>
          ) : (
            <h5>Файли відсутні</h5>
          )}
        </div>
      </TextBlock>

      <TextBlock color="#fff" className="cardText">
        <h3>Файли РНОКПП</h3>
        <div className="col-xl-12">
          {Array.isArray(filesWithPurpose5) &&
          filesWithPurpose5.length !== 0 ? (
            <>
              <div className={styles.cardGrid}>
                {filesWithPurpose5.map((file) => (
                  <div
                    key={file.personalMedObj.mediaObjectID}
                    className={styles.fileCard}
                    style={{ background: "#dbf6fa" }}
                  >
                    <UserFilesForm
                      key={file.personalMedObj.mediaObjectID}
                      file={file}
                      onSave={handleFileSave}
                      setImgModal={setSelectedImage}
                      toggleModal={toggleModal}
                      statusFile={selectStatusFile}
                      isEditable={file?.status?.key !== 1}
                    />
                  </div>
                ))}
              </div>

              <div className="d-flex">
                <div className={styles.select}>
                  <DefaultSelect
                    key="IPN"
                    options={selectStatusVerifFiles}
                    value={ipnStatus}
                    onChange={handleIpnStatusChange}
                  />
                </div>
                <button
                  className={styles.btnPrimaryBlue}
                  onClick={() => handleSubmit(5)}
                >
                  Відправити
                </button>
              </div>
            </>
          ) : (
            <h5>Файли відсутні</h5>
          )}
        </div>
      </TextBlock>
      <ImagePreviewModal
        isOpen={isModalOpen}
        image={selectedImage}
        toggle={toggleModal}
      />
    </>
  );
};

export default UserFilesDetails;
