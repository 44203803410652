import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import useFetchData from "../../../hooks/useFetchData";
import DataTable from "../../UI/DataTable";
import TextBlock from "../../UI/TextBlock";
import RequestType from "../../../models/RequestType";
import { useToast } from "../../../contexts/ToastContext";
import ModalWindowAlert from "../../UI/ModalWindowAlert";
import { ReactComponent as IconEye } from "../../../assets/icons/eye-line.svg";
import { ReactComponent as IconEyeOff } from "../../../assets/icons/eye-off-line.svg";
import { ReactComponent as IconDelete } from "../../../assets/icons/delete-bin-line.svg";
import { ReactComponent as IconAdd } from "../../../assets/icons/add-line.svg";
import styles from "./Brands.module.css";
import Loading from "../../UI/Loading";

const Models = () => {
  const [models, setModels] = useState({});
  const [newModelName, setNewModelName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [selectedModelName, setSelectedModelName] = useState("");
  const { id } = useParams();
  const { fetchData } = useFetchData(`Admin/GetModelsByBrandId/${id}`);
  const { fetchData: postData } = useFetchData(
    "Admin/AddNewModelForBrand",
    RequestType.POST
  );
  const { fetchData: deleteData } = useFetchData(
    "Admin/DeleteModelOfBrand",
    RequestType.POST
  );
  const { fetchData: hideData } = useFetchData(
    "Admin/HideModelOfBrand",
    RequestType.POST
  );
  const { fetchData: showData } = useFetchData(
    "Admin/ShowModel",
    RequestType.POST
  );
  const { showToast } = useToast();

  useEffect(() => {
    fetchData()
      .then((data) => {
        setModels(data);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [fetchData, id]);

  const handleHide = useCallback(
    (idModel) => {
      hideData(idModel)
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setModels((prevData) => ({
              ...prevData,
              models: prevData.models.map((model) =>
                model.id === idModel ? { ...model, isHidden: true } : model
              ),
            }));
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to hide model:", err);
        });
    },
    [hideData, showToast]
  );

  const handleUnhide = useCallback(
    (idModel) => {
      showData(idModel)
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setModels((prevData) => ({
              ...prevData,
              models: prevData.models.map((model) =>
                model.id === idModel ? { ...model, isHidden: false } : model
              ),
            }));
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to unhide model:", err);
        });
    },
    [showData, showToast]
  );

  const handleDeleteClick = useCallback((idModel, modelName) => {
    setSelectedModelId(idModel);
    setSelectedModelName(modelName);
    setModalOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    if (selectedModelId !== null) {
      deleteData(selectedModelId)
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setModels((prevData) => ({
              ...prevData,
              models: prevData.models.filter(
                (model) => model.id !== selectedModelId
              ),
            }));
            setSelectedModelId(null);
            setModalOpen(false);
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to delete model:", err);
        });
    }
  }, [deleteData, selectedModelId, showToast]);

  const handleAddModel = useCallback(() => {
    if (newModelName.trim()) {
      postData({ markId: models.id, name: newModelName.trim() })
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            const newModel = {
              id: `${models.id}_${newModelName.trim()}`,
              name: newModelName.trim(),
              markId: models.id,
              isHidden: false,
            };
            setModels((prevData) => ({
              ...prevData,
              models: [...prevData.models, newModel],
            }));
            setNewModelName("");
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to add model:", err);
        });
    }
  }, [newModelName, postData, models.id, showToast]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (_, index) => index + 1,
      },
      {
        Header: "Назва",
        accessor: "name",
        Cell: ({ row }) => (
          <div>
            {row.original.isHidden ? (
              <span className={styles.hiddenModel}>{row.original.name}</span>
            ) : (
              row.original.name
            )}
          </div>
        ),
      },
      {
        Header: "Відображення",
        accessor: "visibility",
        disableSort: true,
        Cell: ({ row }) => (
          <div className={styles.actionButton}>
            {row.original.isHidden ? (
              <Button
                color="success"
                onClick={() => handleUnhide(row.original.id)}
              >
                <IconEye className={styles.icon} />
                Відобразити
              </Button>
            ) : (
              <Button
                color="secondary"
                onClick={() => handleHide(row.original.id)}
              >
                <IconEyeOff className={styles.icon} />
                Приховати
              </Button>
            )}
          </div>
        ),
      },
      {
        Header: "Видалення",
        accessor: "deletion",
        disableSort: true,
        Cell: ({ row }) => (
          <div className={styles.actionButton}>
            <Button
              color="danger"
              onClick={() =>
                handleDeleteClick(row.original.id, row.original.name)
              }
            >
              <IconDelete className={styles.icon} />
              Видалити
            </Button>
          </div>
        ),
      },
    ],
    [handleHide, handleUnhide, handleDeleteClick]
  );

  if (!models || Object.keys(models).length === 0) {
    return <Loading />;
  }

  return (
    <TextBlock color="#fff" className="cardText">
      <h1>Моделі бренду {models.name}</h1>
      <div className={styles.addContainer}>
        <Input
          value={newModelName}
          onChange={(e) => setNewModelName(e.target.value)}
          placeholder="Введіть назву моделі"
          style={{ marginBottom: "10px" }}
        />
        <Button className={styles.btnAdd} onClick={handleAddModel}>
          <IconAdd className={styles.icon} />
          Додати модель
        </Button>
      </div>

      <ModalWindowAlert
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        text={`Ви впевнені, що хочете видалити модель "${selectedModelName}"?`}
        handleDeleteConfirm={handleDeleteConfirm}
      />

      <DataTable data={models.models || []} columns={columns} />
    </TextBlock>
  );
};

export default Models;
