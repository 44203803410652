import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Popover,
  Button,
  PopoverBody,
} from "reactstrap";
import { ReactComponent as IconBell } from "../../assets/icons/bx-bell.svg";
import { ReactComponent as IconMailOpen } from "../../assets/icons/mail-open.svg";
import NotificationItem from "./NotificationItem";
import useFetchData from "../../hooks/useFetchData";
import styles from "./NotificationsDropdown.module.css";
import { useAuth } from "../../contexts/AuthContext";
import RequestType from "../../models/RequestType";
import { useLocation } from "react-router-dom";
import { useSignalRUser } from "../../contexts/SignalRUserContext";

const NotificationsDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState();
  const [notifications, setNotifications] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { user } = useAuth();
  const location = useLocation();
  const { fetchData } = useFetchData(
    `Notification/GetNotification/${user?.id}`
  );
  const { fetchData: updateNotification } = useFetchData(
    "Notification/IsReviewedNotification",
    RequestType.POST
  );
  const { fetchData: updateNotificationAll } = useFetchData(
    "Notification/MarkAllIsReviewed",
    RequestType.POST
  );
  const { fetchData: deleteNotifiction } = useFetchData(
    "Notification/DeleteNotification",
    RequestType.POST
  );

  const { newNotifiation } = useSignalRUser();

  useEffect(() => {
    if (newNotifiation && Object.keys(newNotifiation).length > 0) {
      setNotifications((prevNotifications) => [
        newNotifiation,
        ...prevNotifications,
      ]);
      setNotificationCount((prevCount) => prevCount + 1);
    }
  }, [newNotifiation]);

  useEffect(() => {
    fetchData()
      .then((data) => {
        const sortedNotifications = data.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        );
        setNotifications(sortedNotifications);
        setNotificationCount(data.filter((n) => !n.isReviewed).length);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [fetchData, location]);

  const markAsRead = async (id) => {
    const isNotification = notifications.find((x) => x.id === id);

    if (isNotification) {
      isNotification.isReviewed = true;
      updateNotification(isNotification)
        .then(() => {
          setNotifications((prevNotifications) => {
            const updatedNotifications = prevNotifications.map((n) =>
              n.id === id ? { ...n, isReviewed: true } : n
            );
            setNotificationCount(
              updatedNotifications.filter((n) => !n.isReviewed).length
            );
            return updatedNotifications;
          });
        })
        .catch((err) => {
          console.error("Failed to fetch data:", err);
        });
    }
  };

  const handleMarkAllAsRead = async () => {
    updateNotificationAll(user?.id)
      .then(() => {
        setNotifications((prevNotifications) =>
          prevNotifications.map((n) => ({ ...n, isReviewed: true }))
        );
        setNotificationCount(0);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  };

  const removeNotification = (id) => {
    deleteNotifiction(id)
      .then(() => {
        setNotifications((prevNotifications) => {
          var newNotifications = prevNotifications.filter((n) => n.id !== id);
          setNotificationCount(
            newNotifications.filter((n) => !n.isReviewed).length
          );
          return newNotifications;
        });
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className={styles.navNotification}
    >
      <DropdownToggle nav caret={false} className={styles.navNotification}>
        <IconBell className={styles.iconBell} />
        {notificationCount !== 0 && (
          <span className={`${styles.badget} badge`}>{notificationCount}</span>
        )}
      </DropdownToggle>
      <DropdownMenu end className={`${styles.dropdownMenu} dropdown-menu-left`}>
        <div className={styles.header}>
          <p className="mb-0 fs-17 fw-semibold">Сповіщення</p>
          {notificationCount > 0 && (
            <>
              <Button
                id="PopoverReadAll"
                className={`btn ${styles.btnReviewAll}`}
                onClick={handleMarkAllAsRead}
                onMouseEnter={togglePopover}
                onMouseLeave={togglePopover}
              >
                <IconMailOpen />
              </Button>
              <Popover
                placement="bottom"
                isOpen={popoverOpen}
                target="PopoverReadAll"
                trigger="hover"
              >
                <PopoverBody className={styles.popoverBody}>
                  <strong>Відмітити як прочитані: {notificationCount}</strong>
                </PopoverBody>
              </Popover>
            </>
          )}
        </div>
        <div className={styles.divider}></div>
        {notifications.length !== 0 ? (
          <div className={`${styles.notificationsList} ${styles.scroll}`}>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onRemove={removeNotification}
                onMarkAsRead={markAsRead}
              />
            ))}
          </div>
        ) : (
          <div
            className={`${styles.notificationsList} ${styles.notNotification}`}
          >
            Ви не маєте сповіщень
          </div>
        )}
        <div className={styles.divider}></div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationsDropdown;
