import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  Login,
  ConfirmRegistration,
  ForgotPassword,
  GoogleLogin,
  ResetPassword,
  VerifyToken,
  Logout,
} from "../repositories/AccountRepository";
import { useApi } from "./ApiContext";
import useFetchData from "../hooks/useFetchData";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const apiUrl = useApi();
  const { fetchData } = useFetchData("AccountManager/get-user");
  const moderator = ["Moderator", "SuperAdmin"];
  const admin = ["SuperAdmin"];
  const fetchUser = useCallback(async () => {
    fetchData()
      .then((data) => {
        setUser(data);
        localStorage.removeItem("hasAccessToAdminPages");
        localStorage.removeItem("hasAccessToModeratorPages");
        if (admin.includes(data?.role)) {
          localStorage.setItem("hasAccessToAdminPages", true);
        } else if (moderator.includes(data?.role)) {
          localStorage.setItem("hasAccessToModeratorPages", true);
        }
      })
      .catch((error) => {
        console.error("Fetch user error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const login = async (formData) => {
    try {
      const response = await Login(formData, apiUrl);
      if (response.success) {
        await fetchUser();
        localStorage.setItem("isAuthenticated", true);
        return { success: true };
      } else {
        return {
          success: false,
          message: response.message || "Помилка авторизації",
        };
      }
    } catch (error) {
      console.error("Login error:", error);
      return { success: false, message: error.message };
    }
  };

  const registration = async (formData) => {
    try {
      const response = await ConfirmRegistration(formData, apiUrl);
      if (response.responseData != null) {
        return { success: true };
      } else {
        return {
          success: false,
          message: response.message || "Помилка Реєстрації",
        };
      }
    } catch (error) {
      console.error("Registration error:", error);
      return { success: false, message: error.message };
    }
  };

  const forgotPassword = async (formData) => {
    try {
      const response = await ForgotPassword(formData, apiUrl);

      if (response.responseData != null) {
        return { success: response.responseData };
      } else {
        return { success: false, message: response.error };
      }
    } catch (error) {
      console.error("Forget password error:", error);
      return { success: false, message: error.message };
    }
  };

  const resetPassword = async (formData) => {
    try {
      const response = await ResetPassword(formData, apiUrl);

      if (response.responseData != null) {
        return { success: response.responseData };
      } else {
        return { success: false, message: response.error };
      }
    } catch (error) {
      console.error("Reset password error:", error);
      return { success: false, message: error.message };
    }
  };

  const verifyToken = async (formData) => {
    try {
      const response = await VerifyToken(formData, apiUrl);

      if (response.responseData != null) {
        return { success: response.responseData };
      } else {
        return { success: false, message: response.error };
      }
    } catch (error) {
      console.error("Verify token error:", error);
      return { success: false, message: error.message };
    }
  };

  const loginWithGoogle = async (googleTokenId) => {
    try {
      const response = await GoogleLogin(googleTokenId, apiUrl);
      if (response && response.responseData) {
        await fetchUser();
        localStorage.setItem("isAuthenticated", true);
        return { success: true };
      } else {
        return {
          success: false,
          message: response.message || "Помилка авторизації Google",
        };
      }
    } catch (error) {
      console.error("Google login error:", error);
      return { success: false, message: error.message };
    }
  };

  const logout = async () => {
    await Logout(apiUrl);
    setUser(null);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("hasAccessToAdminPages");
    localStorage.removeItem("hasAccessToModeratorPages");
  };

  const isAuthenticated = () => {
    return !!localStorage.getItem("isAuthenticated");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        registration,
        logout,
        isAuthenticated,
        forgotPassword,
        resetPassword,
        verifyToken,
        loginWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
