import React from "react";
import { Popover, PopoverBody } from "reactstrap";

const Hint = ({
  id,
  popoverOpen,
  togglePopover,
  hint,
  className,
  placement = "bottom",
}) => {
  return (
    <>
      <img
        src="/images/Question_mark.png"
        className={className}
        id={id}
        alt="Question"
        onMouseEnter={togglePopover}
        onMouseLeave={togglePopover}
      />
      <Popover
        placement={placement}
        isOpen={popoverOpen}
        target={id}
        toggle={togglePopover}
      >
        <PopoverBody>{hint}</PopoverBody>
      </Popover>
    </>
  );
};

export default Hint;
