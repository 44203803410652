import React, { useState } from "react";
import Hint from "./Hint";
import CustomPagination from "./CustomPagination";
import { truncateFileName } from "../../utils/fileHelpers";
import { getStatusText } from "../../utils/stylesHelpers";
import styles from "./UserFilesModal.module.css";

const UserFileList = ({ id, title, files }) => {
  const [popoversState, setPopoversState] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const togglePopover = (popoverId) => {
    setPopoversState((prevState) => ({
      ...prevState,
      [popoverId]: !prevState[popoverId],
    }));
  };

  const indexOfLastFile = currentPage * itemsPerPage;
  const indexOfFirstFile = indexOfLastFile - itemsPerPage;
  const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(files.length / itemsPerPage);

  return (
    <div className="mt-3">
      <h5>{title}</h5>
      {currentFiles.length > 0 ? (
        <div className="d-flex">
          {currentFiles.map((file, index) => (
            <div key={index} className={styles.card}>
              <div className="d-flex justify-content-between mb-3">
                <span className={styles.text} title={file.fileName}>
                  {truncateFileName(file.fileName, 12)}
                </span>
                <div>
                  {file.fileRejectDescription && (
                    <Hint
                      id={`file-${file.purpose.key}-${index}`}
                      className={styles.hint}
                      popoverOpen={
                        popoversState[`file-${file.purpose.key}-${index}`]
                      }
                      togglePopover={() =>
                        togglePopover(`file-${file.purpose.key}-${index}`)
                      }
                      placement="bottom"
                      hint={file.fileRejectDescription}
                    />
                  )}
                  {getStatusText(file.status || {}, styles)}
                </div>
              </div>
              <div className={`${styles.text} ${styles.date}`}>
                {new Date(file.dateLoad).toLocaleDateString("uk-UA", {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                })}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Файли відсутні.</p>
      )}

      <CustomPagination
        currentPage={currentPage}
        pageCount={pageCount}
        paginate={paginate}
      />
    </div>
  );
};

export default UserFileList;
