import { useEffect, useRef } from "react";
import createSignalRConnectionForPersonalizedMessage from "../services/createSignalRConnectionForPersonalizedMessage";

const useSignalRForPersonalizedMessage = (
  userId,
  setToastMessage,
  setNotification
) => {
  const connectionRef = useRef(null);

  useEffect(() => {
    if (userId) {
      const connection = createSignalRConnectionForPersonalizedMessage(userId);
      connectionRef.current = connection;

      connection.on("ReceiveWelcomeMessage", (message) => {
        setToastMessage(message);
      });

      connection.on("ReceivePersonalizedSystemMessage", (message) => {
        setToastMessage(message.text);
      });

      connection.on("ReceiveSystemNotification", (message) => {
        if (message.notification) {
          setNotification(message.notification);
        }
      });

      return () => {
        connection.stop();
      };
    }
  }, [userId, setToastMessage, setNotification]);

  return connectionRef;
};

export default useSignalRForPersonalizedMessage;
