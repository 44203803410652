/**
 * Повертає клас стилю залежно від статусу.
 * @param {number} status - Статус файлу.
 * @returns {string} - Назва класу для статусу.
 */
export const getStatusClassName = (status, styles) => {
  switch (status) {
    case 0:
      return styles.statusNone;
    case 1:
      return styles.statusInProcessing;
    case 2:
      return styles.statusConfirmed;
    case 3:
      return styles.statusPartiallyConfirmed;
    case 4:
      return styles.statusRejected;
    default:
      return styles.statusNone;
  }
};

/**
 * Повертає клас стилю залежно від статусу.
 * @param {object} status - Об'єкт статусу з полями key і value.
 * @param {object} styles - Об'єкт з класами стилів.
 * @returns {JSX.Element|null} - Елемент зі статусом або null.
 */
export const getStatusText = (status, styles) => {
  let statusClass = "";

  if (!status) {
    return null;
  } else if (status?.key === 1) {
    statusClass = styles.statusConfirmed;
  } else if (status?.key === 2) {
    statusClass = styles.statusRejected;
  } else if (status?.key === 0) {
    statusClass = styles.statusInProcessing;
  }

  return <span className={statusClass}>{status?.value}</span>;
};
