import React, { useCallback, useEffect, useState } from "react";
import TextBlock from "../UI/TextBlock";
import FormRowSetings from "../UI/FormRowSetings";
import FormRowSwitchesSetings from "../UI/FormRowSwitchesSetings";
import { useToast } from "../../contexts/ToastContext";
import useFetchData from "../../hooks/useFetchData";
import RequestType from "../../models/RequestType";
import FormRowSetingsPhoneNumber from "../UI/FormRowSetingsPhoneNumber";
import ResetPasswordModal from "./ResetPasswordModal";
import SetPasswordModal from "./SetPasswordModal";
import Hint from "../UI/Hint";
import UserFilesModal from "../UI/UserFilesModal";
import { getStatusClassName } from "../../utils/stylesHelpers";
import { toggleState } from "../../utils/stateHelpers";
import styles from "./ProfileContent.module.css";

const Settings = () => {
  const [user, setUser] = useState({});
  const { showToast } = useToast();
  const [settings, setSettings] = useState({
    isAnnouncementsStoryHidden: false,
    isBidsStoryHidden: false,
  });
  const [hasPassword, setHasPassword] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSetPassModalOpen, setIsSetPassModalOpen] = useState(false);
  const [modalFiles, setModalFiles] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [statusPassport, setStatusPassport] = useState({
    key: 0,
    value: "Відсутні",
  });
  const [statusIPN, setStatusIPN] = useState({ key: 0, value: "Відсутні" });

  const { fetchData } = useFetchData(`Profile/GetUserProfileSettings`);
  const { fetchData: fetchChangePassword } = useFetchData(
    `Profile/ChangePassword`,
    RequestType.POST
  );
  const { fetchData: fetchSetPassword } = useFetchData(
    `Profile/SetPassword`,
    RequestType.POST
  );
  const { fetchData: fetchUpdatePhoneNumber } = useFetchData(
    `Profile/SetPhoneNumber`,
    RequestType.POST
  );

  useEffect(() => {
    fetchData()
      .then((data) => {
        setUser(data.user);
        const settingsUser = {
          isAnnouncementsStoryHidden: data.user.isStoryHidden,
          isBidsStoryHidden: data.user.isLotStoryHidden,
        };
        setSettings(settingsUser);
        setHasPassword(data.hasPassword);
        setStatusPassport(data.statusPassport);
        setStatusIPN(data.statusIPN);
      })
      .catch((err) =>
        showToast("Помилка", `Error fetching user data: ${err}`, "danger")
      );
  }, [fetchData, showToast]);

  const handlePasswordChange = (oldPassword, newPassword, setErrorMessage) => {
    fetchChangePassword({ oldPassword, newPassword })
      .then((data) => {
        if (data.success) {
          toggleModal();
          showToast("Успіх", "Пароль успішно змінено", "success");
        } else {
          if (data.errors && data.errors.length > 0) {
            const allErrors = data.errors.join("<br/>");
            setErrorMessage(allErrors);
          } else {
            setErrorMessage(data.message || "Щось пішло не так");
          }
        }
      })
      .catch((err) => {
        setErrorMessage(`Помилка при зміні пароля: ${err}`);
      });
  };

  const handleSetPassword = (newPassword, setErrorMessage) => {
    fetchSetPassword({ newPassword })
      .then((data) => {
        if (data.success) {
          toggleModal();
          showToast("Успіх", "Пароль успішно встановлено", "success");
        } else {
          if (data.errors && data.errors.length > 0) {
            const allErrors = data.errors.join("<br/>");
            setErrorMessage(allErrors);
          } else {
            setErrorMessage(data.message || "Щось пішло не так");
          }
        }
      })
      .catch((err) => {
        setErrorMessage(`Помилка при встановленні пароля: ${err}`);
      });
  };

  const toggleModal = () => toggleState(isModalOpen, setIsModalOpen);

  const toggleSetPassModal = () =>
    toggleState(isSetPassModalOpen, setIsSetPassModalOpen);

  const togglePopover = () => toggleState(popoverOpen, setPopoverOpen);

  const toggleModalFiles = () => toggleState(modalFiles, setModalFiles);

  const handlePhoneSave = useCallback(
    (newPhoneNumber) => {
      fetchUpdatePhoneNumber({ phone: newPhoneNumber })
        .then((data) => showToast("Успіх", data.message, "success"))
        .catch((err) =>
          showToast(
            "Помилка",
            `Error fetching reset password data: ${err}`,
            "danger"
          )
        );
    },
    [fetchUpdatePhoneNumber, showToast]
  );

  return (
    <>
      <TextBlock color="#dbf6fa" className="cardText">
        <h2 className={styles.h2}>
          <strong>Аккаунт</strong>
        </h2>
        {hasPassword ? (
          <div className="row mb-3">
            <div className="col-sm-8 d-flex align-items-center">
              <div className="form-group">
                <label className={styles.lableSetings}>
                  <strong>Пароль</strong>
                </label>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-end align-items-end">
              <button
                type="button"
                className={`float-right ${styles.btnPrimaryBlue}`}
                onClick={toggleModal}
              >
                <strong>Змінити пароль</strong>
              </button>
            </div>
            <ResetPasswordModal
              isOpen={isModalOpen}
              toggleModal={toggleModal}
              handlePasswordChange={handlePasswordChange}
            />
          </div>
        ) : (
          <div className="row mb-3">
            <div className="col-sm-8 d-flex align-items-center">
              <div className="form-group">
                <label className={styles.lableSetings}>
                  <strong>Встановити пароль</strong>{" "}
                  <Hint
                    id="setPass"
                    popoverOpen={popoverOpen}
                    className={styles.hint}
                    togglePopover={togglePopover}
                    hint="Ви можите встановити пароль і зможете входити і через звичайну авторизацію сайту"
                    placement="right"
                  />
                </label>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-end align-items-end">
              <button
                type="button"
                className={`float-right ${styles.btnPrimaryBlue}`}
                onClick={toggleSetPassModal}
              >
                <strong>Встановити пароль</strong>
              </button>
            </div>
            <SetPasswordModal
              isOpen={isSetPassModalOpen}
              toggleModal={toggleSetPassModal}
              handleSetPassword={handleSetPassword}
            />
          </div>
        )}

        <FormRowSetings
          label="Банківська карта"
          text={user?.cardNumber}
          buttonText="Змінити карту"
        />
        <FormRowSetingsPhoneNumber
          label="Номер телефону"
          text={user?.phoneNumber}
          buttonText="Змінити номер"
          inputType="tel"
          regex={/^\d{9}$/}
          onSave={handlePhoneSave}
          countryCode="+380"
          formatFunction={(value) => {
            const cleaned = value.replace(/\D/g, "");
            return cleaned.length === 10
              ? `+380 ${cleaned.slice(1, 3)}-${cleaned.slice(
                  3,
                  5
                )}-${cleaned.slice(5, 7)}-${cleaned.slice(7)}`
              : value;
          }}
          placeholder="Введіть номер телефону"
        />
        <div className="row mb-3">
          <div className="col-sm-10">
            <div className="form-group">
              <label>
                <strong>Верифікація аккаунта</strong>
                <span className={styles.statusSpan}>
                  Паспорт
                  <span
                    className={`${styles.statusFile} ${getStatusClassName(
                      statusPassport.key,
                      styles
                    )}`}
                  >
                    {statusPassport.value}
                  </span>
                </span>
                <span className={styles.statusSpan}>
                  РНОКПП
                  <span
                    className={`${styles.statusFile} ${getStatusClassName(
                      statusIPN.key,
                      styles
                    )}`}
                  >
                    {statusIPN.value}
                  </span>
                </span>
              </label>
              <div className="d-flex align-items-center mt-3">
                <p>Необхідно завантажити фото паспорта та РНОКПП</p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 mb-3 d-flex flex-column justify-content-center align-items-end">
            <button
              type="button"
              className={`float-right ${styles.btnPrimaryBlue}`}
              onClick={toggleModalFiles}
            >
              <strong>Мої файли</strong>
            </button>
          </div>
          <UserFilesModal isOpen={modalFiles} toggleModal={toggleModalFiles} />
        </div>
        <strong>
          <FormRowSwitchesSetings
            key={2}
            label="Приховати історію оголошень"
            type={"IsAnnouncementsStoryHidden"}
            value={settings?.isAnnouncementsStoryHidden}
            userId={user?.id}
          />
        </strong>
        <strong>
          <FormRowSwitchesSetings
            key={3}
            label="Приховати історію ставок"
            type={"IsBidsStoryHidden"}
            value={settings?.isBidsStoryHidden}
            userId={user?.id}
          />
        </strong>
      </TextBlock>
      <TextBlock color="#fff" className="cardText">
        <h2 className={styles.h2}>
          <strong>Загальні сповіщення</strong>
        </h2>
        <FormRowSwitchesSetings
          key={4}
          label="Повідомте мене, коли мене згадають у коментарях"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={5}
          label="Повідомте мене, коли хтось відповість мені в коментарях"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={6}
          label="Вмикати звук під час розміщення ставок (лише для ПК)"
          value={true}
          userId={user?.id}
        />
      </TextBlock>
      <TextBlock color="#dbf6fa" className="cardText">
        <h2 className={styles.h2}>
          <strong>Сповіщення про список спостереження</strong>
        </h2>
        <FormRowSwitchesSetings
          key={7}
          label="Повідомте мене за 1 годину до закінчення аукціону"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={8}
          label="Повідомте мене, коли з'являться нові пропозиції"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={9}
          label="Повідомте мене, коли з'являться нові коментарі"
          value={true}
        />
        <FormRowSwitchesSetings
          key={10}
          label="Повідомте мене, коли будуть відповіді на запитання"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={11}
          label="Повідомте мене про результати аукціонів"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={12}
          label="Отримувати ці сповіщення також на електронну пошту"
          value={true}
          userId={user?.id}
        />
      </TextBlock>
      <TextBlock color="#fff" className="cardText">
        <h2 className={styles.h2}>
          <strong>Сповіщення учасника торгів</strong>
        </h2>
        <FormRowSwitchesSetings
          key={13}
          label="Повідомте мене, коли мою ставку переб'ють"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={14}
          label="Повідомте мене після аукціону, якщо я не виграю"
          value={true}
          userId={user?.id}
        />
      </TextBlock>
      <TextBlock color="#dbf6fa" className="cardText">
        <h2 className={styles.h2}>
          <strong>Сповіщення продавця</strong>
        </h2>
        <FormRowSwitchesSetings
          key={15}
          label="Надсилайте нові коментарі на електронну пошту"
          value={true}
          userId={user?.id}
        />
        <FormRowSwitchesSetings
          key={16}
          label="Надсилайте нові пропозиції на електронну пошту"
          value={true}
          userId={user?.id}
        />
      </TextBlock>
    </>
  );
};

export default Settings;
