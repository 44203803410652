import React from "react";
import Button from "./Button";
import styles from "./Chat.module.css";
import { useAuth } from "../../contexts/AuthContext";

const CommentList = ({
  comments,
  parentId,
  deleteComment,
  handleLinkClick,
}) => {
  const { user } = useAuth();
  return (
    <ul className={styles.comments}>
      {comments.map((comment) => (
        <li key={comment.id} className={styles.comment}>
          <div className={styles.commentHeader}>
            <div>
              <a
                href={`Profile?userId=${comments.userId}`}
                className={styles.userUrl}
                onClick={(event) => handleLinkClick(event)}
              >
                <img
                  src={comment.userImg}
                  alt="user"
                  className={styles.userImg}
                />
                <span className={styles.userName}>{comment.userName}</span>
              </a>
            </div>
            {user?.role === "Moderator" && (
              <Button
                onClick={() => deleteComment(parentId, comment.id)}
                className={styles.deleteButton}
              >
                <img src="/images/Dump.png" alt="Dump" />
              </Button>
            )}
          </div>
          <div className={styles.messageContent}>{comment.text}</div>
        </li>
      ))}
    </ul>
  );
};

export default CommentList;
