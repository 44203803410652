/**
 * Скорочує ім'я файлу, якщо воно занадто довге.
 * @param {string} fileName - Назва файлу, яку потрібно скоротити.
 * @param {number} size - Максимальна довжина назви файлу, після якої додаються три крапки.
 * @returns {string} - Скорочена назва файлу з три крапками, якщо вона перевищує зазначений розмір.
 */
export const truncateFileName = (fileName, size) => {
  if (fileName.length > size) {
    return fileName.slice(0, size) + "...";
  }
  return fileName;
};
