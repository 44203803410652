import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../../hooks/useFetchData";
import DataTable from "../../UI/DataTable";
import TextBlock from "../../UI/TextBlock";
import { Button } from "reactstrap";
import { ReactComponent as IconEye } from "../../../assets/icons/eye-line.svg";
import { formatDate } from "../../../utils/dateTimeHelpers";
import { getStatusClassName } from "../../../utils/stylesHelpers";
import styles from "./UsersFile.module.css";

const UsersFile = () => {
  const [users, setUsers] = useState([]);
  const { fetchData } = useFetchData("Moderator/GetModerationUsers");
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [fetchData]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (_, index) => index + 1,
      },
      {
        Header: "Ім'я Прізвище",
        accessor: "fullName",
        Cell: ({ row }) => (
          <span>
            {row.original.firstName} {row.original.surname}
          </span>
        ),
      },
      {
        Header: "Пошта",
        accessor: "email",
      },
      {
        Header: "Логін",
        accessor: "userName",
      },
      {
        Header: "Дата реєстрації",
        accessor: "registrationDate",
        Cell: ({ row }) => (
          <span>{formatDate(row.original.registrationDate)}</span>
        ),
      },
      {
        Header: "Дані паспорту",
        accessor: "passportStatus",
        Cell: ({ row }) => (
          <span
            className={`${styles.statusFile} ${getStatusClassName(
              row.original.passportStatus.key,
              styles
            )}`}
          >
            {row.original.passportStatus.value}
          </span>
        ),
      },
      {
        Header: "Дані РНОКПП",
        accessor: "ipnStatus",
        Cell: ({ row }) => (
          <span
            className={`${styles.statusFile} ${getStatusClassName(
              row.original.ipnStatus.key,
              styles
            )}`}
          >
            {row.original.ipnStatus.value}
          </span>
        ),
      },
      {
        Header: "Відображення",
        accessor: "detail",
        disableSort: true,
        Cell: ({ row }) => (
          <div className={styles.actionButton}>
            <Button
              color="success"
              onClick={() =>
                navigate(`/inModeration/UsersFile/${row.original.userId}`)
              }
            >
              <IconEye className={styles.icon} />
              Детальніше
            </Button>
          </div>
        ),
      },
    ],
    [navigate]
  );

  return (
    <TextBlock color="#fff" className="cardText">
      <h2>
        <strong>Користувачі</strong>
      </h2>
      <DataTable data={users} columns={columns} />
    </TextBlock>
  );
};

export default UsersFile;
