import React, { useCallback, useEffect, useState } from "react";
import { Form, FormGroup } from "reactstrap";
import TextInput from "../../UI/TextInput";
import AuthButton from "../../UI/AuthButton";
import { useLocation, useNavigate } from "react-router-dom";
import Identification from "../../UI/Identification";
import { useAuth } from "../../../contexts/AuthContext";
import styles from "../../UI/Identification.module.css";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState(null);
  const { resetPassword, verifyToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const email = queryParams.get("email");
  const code = queryParams.get("code");

  const verifyTokenOnLoad = useCallback(
    async (email, code) => {
      try {
        const result = await verifyToken({ email, code });
        if (result.success === false) {
          navigate("/resetpasswordconfirmation", {
            state: {
              header: "Не дійсне посилання",
              contextText:
                "Час на скидання паролю сплинув або ви вже скинули пароль.",
            },
            replace: true,
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    [navigate, verifyToken]
  );

  useEffect(() => {
    verifyTokenOnLoad(email, code);
    /* eslint react-hooks/exhaustive-deps: "off" */
  }, [email, code]);

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.password) {
      errors.password = "Пароль не може бути пустим.";
    }
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Паролі не співпадають.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});

    const dataToSend = {
      ...formData,
      email,
      code,
    };

    try {
      const result = await resetPassword(dataToSend);
      if (result.success === false) {
        setError(result.message);
      }
      navigate("/resetpasswordconfirmation", { replace: true });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Identification header="Відновлення паролю" hideGoogleButton={true}>
      {error && (
        <div
          className={styles.errorMessage}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
      {Object.keys(validationErrors).length > 0 && (
        <div className={styles.errorMessage}>
          {Object.values(validationErrors).map((err, index) => (
            <div key={index}>{err}</div>
          ))}
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <TextInput
            type="password"
            id="password"
            placeholder="Пароль"
            value={formData.password}
            isPassword={true}
            onChange={(value) => handleChange("password", value)}
            error={validationErrors.password}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            type="password"
            id="confirmPassword"
            placeholder="Підтвердження паролю"
            value={formData.confirmPassword}
            isPassword={true}
            onChange={(value) => handleChange("confirmPassword", value)}
            error={validationErrors.confirmPassword}
          />
        </FormGroup>
        <AuthButton type="submit">Відновити</AuthButton>
      </Form>
    </Identification>
  );
};

export default ResetPassword;
