import React, { useEffect, useState } from "react";
import DefaultSelect from "../../UI/DefaultSelect";
import { useToast } from "../../../contexts/ToastContext";
import { getStatusText } from "../../../utils/stylesHelpers";
import styles from "./UserFilesForm.module.css";

const UserFilesForm = ({
  file,
  onSave,
  setImgModal,
  toggleModal,
  statusFile,
  isEditable,
}) => {
  const initialStatus = statusFile.find(
    (status) => status.value === `${file.status.key}`
  ) || { value: "", label: "" };

  const [status, setStatus] = useState(initialStatus);
  const [rejectReason, setRejectReason] = useState("");
  const [error, setError] = useState(false);

  const { showToast } = useToast();

  useEffect(() => {
    const currentStatus = statusFile.find(
      (status) => status.value === `${file.status.key}`
    ) || { value: "", label: "" };
    setStatus(currentStatus);
  }, [file, statusFile]);

  const handleStatusChange = (selectedOption) => {
    setStatus({ value: selectedOption.value, label: selectedOption.label });
    if (selectedOption.value !== "2") {
      setRejectReason("");
      setError(false);
    }
  };

  const handleRejectReasonChange = (event) => {
    const textarea = event.target;
    setRejectReason(event.target.value);

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    setError(false);
  };

  const handleSubmit = () => {
    if (status.value === "2" && !rejectReason) {
      setError(true);
      showToast(
        "Помилка",
        `${file.personalMedObj.fileName} при відхиленні треба вказати причину`,
        "danger"
      );
      return;
    }

    onSave(file.personalMedObj.mediaObjectID, status.value, rejectReason);
    showToast(
      "Успіх",
      `${file.personalMedObj.fileName} статус встановлено`,
      "success"
    );
  };

  const getFileType = (url) => {
    const ext = url.split(".").pop().toLowerCase();
    return ext;
  };

  const fileType = getFileType(file.personalMedObj.source);

  const handleImageClick = (image) => {
    setImgModal(image);
    toggleModal();
  };

  return (
    <div className={styles.cardFile}>
      <div className="d-flex flex-column align-items-center">
        {file.personalMedObj.fileName.match(/\.(jpg|jpeg|png|gif)$/) ? (
          <img
            src={file.personalMedObj.source}
            alt={file.personalMedObj.fileName}
            width="160"
            style={{ cursor: "pointer" }}
            onClick={() => handleImageClick(file.personalMedObj.source)}
          />
        ) : fileType === "pdf" ? (
          <div>
            <a
              href={file.personalMedObj.source}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/pdf.png" alt="PDF Icon" width="60" />
            </a>
          </div>
        ) : (
          <p>Невідомий тип файлу</p>
        )}
        <h6 className={styles.fileName}>{file.personalMedObj.fileName}</h6>
      </div>
      {status.value === "2" && isEditable && (
        <div className={styles.boxTextArea}>
          <textarea
            id={file.personalMedObj.mediaObjectID}
            className={`${styles.textArea} ${error ? styles.error : ""}`}
            value={rejectReason}
            onChange={handleRejectReasonChange}
            placeholder="Введіть причину відхилення"
          />
        </div>
      )}
      {isEditable && (
        <div className="d-flex">
          <div className={styles.select}>
            <DefaultSelect
              key={file.personalMedObj.mediaObjectID}
              options={statusFile}
              value={status}
              onChange={handleStatusChange}
            />
          </div>
          <button className={styles.btnPrimaryBlue} onClick={handleSubmit}>
            Зберегти
          </button>
        </div>
      )}
      {!isEditable && (
        <div className="d-flex justify-content-end aligns-item-end">
          {getStatusText(file.status, styles)}
        </div>
      )}
    </div>
  );
};

export default UserFilesForm;
