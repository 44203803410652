import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useToast } from "../../contexts/ToastContext";
import useFetchData from "../../hooks/useFetchData";
import UserFileList from "./UserFileList";
import UploadArea from "./UploadArea";
import RequestType from "../../models/RequestType";
import styles from "./UserFilesModal.module.css";

const UserFilesModal = ({ isOpen, toggleModal }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [userPassport, setUserPassport] = useState([]);
  const [userIPN, setUserIPN] = useState([]);

  const [filesPassport, setFilesPassport] = useState([]);
  const [filesIPN, setFilesIPN] = useState([]);

  const { showToast } = useToast();
  const { fetchData: getUserFiles } = useFetchData(`Profile/GetUserFiles`);

  const { fetchData: setPassport } = useFetchData(
    "/Profile/SetPassportFiles",
    RequestType.POST
  );
  const { fetchData: setIPN } = useFetchData(
    "/Profile/SetIPNFiles",
    RequestType.POST
  );

  useEffect(() => {
    if (isOpen) {
      fetchUserFiles();
    }
  }, [isOpen]);

  const fetchUserFiles = async () => {
    getUserFiles()
      .then((data) => {
        setUserPassport(data.passport);
        setUserIPN(data.ipn);
      })
      .catch((err) => showToast("Помилка", err, "danger"));
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const uploadPassportFilesToServer = async () => {
    if (filesPassport.length === 0) {
      showToast(
        "Помилка",
        "Будь ласка, виберіть файли для завантаження",
        "danger"
      );
      return;
    }
    const formData = new FormData();
    filesPassport.forEach((file) => {
      formData.append("files", file);
    });
    setPassport(formData)
      .then((data) => {
        showToast("Успіх", data.message, "success");
        setFilesPassport([]);
        fetchUserFiles();
      })
      .catch((err) => {
        showToast("Помилка", err, "danger");
      });
  };

  const uploadIPNFilesToServer = async () => {
    if (filesIPN.length === 0) {
      showToast(
        "Помилка",
        "Будь ласка, виберіть файли для завантаження",
        "danger"
      );
      return;
    }
    const formData = new FormData();
    filesIPN.forEach((file) => {
      formData.append("files", file);
    });
    setIPN(formData)
      .then((data) => {
        showToast("Успіх", data.message, "success");
        setFilesIPN([]);
        fetchUserFiles();
      })
      .catch((err) => {
        showToast("Помилка", err, "danger");
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size="lg" centered>
      <ModalHeader toggle={toggleModal} className="mb-3"></ModalHeader>
      <ModalBody>
        <Nav justified tabs>
          <NavItem>
            <NavLink
              className={classnames(styles.navLink, {
                active: activeTab === "1",
              })}
              onClick={() => toggleTab("1")}
            >
              Завантажити файли
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames(styles.navLink, {
                active: activeTab === "2",
              })}
              onClick={() => toggleTab("2")}
            >
              Перегляд моїх файлів
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <h5 className="mt-3">Паспорт</h5>
            <div className="row mb-3">
              <div className="col-sm-8 d-flex justify-content-center align-items-center">
                <UploadArea
                  files={filesPassport}
                  setFiles={setFilesPassport}
                  id="filePassport"
                  maxFiles={3}
                  allowedFileTypes={["image/", "application/pdf"]}
                  viewFileStyle={styles.fileStyle}
                />
              </div>
              <div className="col-sm-4 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className={`float-right ${styles.btnPrimaryBlue}`}
                  onClick={uploadPassportFilesToServer}
                >
                  <strong>Завантажити</strong>
                </button>
              </div>
            </div>
            <h5>РНОКПП</h5>
            <div className="row mb-3">
              <div className="col-sm-8 d-flex justify-content-center align-items-center">
                <UploadArea
                  files={filesIPN}
                  setFiles={setFilesIPN}
                  id="fileIPN"
                  maxFiles={3}
                  allowedFileTypes={["image/", "application/pdf"]}
                  viewFileStyle={styles.fileStyle}
                />
              </div>
              <div className="col-sm-4 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className={`float-right ${styles.btnPrimaryBlue}`}
                  onClick={uploadIPNFilesToServer}
                >
                  <strong>Завантажити</strong>
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <UserFileList
              id="passport"
              title={"Паспорт"}
              files={userPassport}
            />
            <UserFileList id="ipn" title={"РНОКПП"} files={userIPN} />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default UserFilesModal;
