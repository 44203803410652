import React, { useRef } from "react";
import styles from "./UploadArea.module.css";

const UploadArea = ({
  files,
  setFiles,
  mainPhoto,
  setMainPhoto,
  id,
  maxFiles = null,
  allowedFileTypes = ["image/", "video/"],
  viewFileStyle,
}) => {
  const fileInputRef = useRef(null);

  const acceptTypes = allowedFileTypes
    .map((type) => (type === "application/pdf" ? ".pdf" : `${type}*`))
    .join(",");

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = [...event.dataTransfer.files];
    const filteredFiles = filterFiles(newFiles);
    if (filteredFiles.length > 0) {
      setFilesWithLimit(filteredFiles);
    }
  };

  const handleFileInput = (event) => {
    const newFiles = [...event.target.files];
    const filteredFiles = filterFiles(newFiles);
    if (filteredFiles.length > 0) {
      setFilesWithLimit(filteredFiles);
    }
  };

  const setFilesWithLimit = (newFiles) => {
    setFiles((prevFiles) => {
      let updatedFiles = [...prevFiles, ...newFiles];

      if (maxFiles !== null && updatedFiles.length > maxFiles) {
        updatedFiles = updatedFiles.slice(0, maxFiles);
      }

      if (!mainPhoto && setMainPhoto && updatedFiles.length > 0) {
        const firstImage = updatedFiles.find(
          (file) =>
            file.type.startsWith("image/") || file.type.startsWith("video/")
        );
        if (firstImage) {
          setMainPhoto(firstImage);
        }
      }

      return updatedFiles;
    });
  };

  const filterFiles = (files) => {
    return Array.from(files).filter((file) =>
      allowedFileTypes.some((type) => file.type.startsWith(type))
    );
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    const removedFile = newFiles.splice(index, 1)[0];
    setFiles(newFiles);
    if (mainPhoto && setMainPhoto && mainPhoto === removedFile) {
      const nextMainPhoto =
        newFiles.find((file) => file.type.startsWith("image/")) || undefined;
      setMainPhoto(nextMainPhoto);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileClick = () => {
    document.getElementById(id).click();
  };

  const handleMainPhotoChange = (event, file) => {
    event.stopPropagation();
    if (setMainPhoto) {
      setMainPhoto(file);
    }
  };

  return (
    <div
      className={`mb-4 ${styles.uploadArea}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleFileClick}
    >
      {files.length === 0 && (
        <>
          <img
            src="/images/Send_file.png"
            alt="Send_file"
            style={{ width: "60px" }}
          />
          <p>Клікніть сюди для вибору файлів, або перетягніть їх сюди</p>
        </>
      )}

      <input
        ref={fileInputRef}
        id={id}
        type="file"
        onChange={handleFileInput}
        style={{ display: "none" }}
        accept={acceptTypes}
        multiple
      />
      <div className={styles.files}>
        {files.map((file, index) => (
          <div key={index} className={styles.divImgesOrVideo}>
            <img
              src="/images/Cancel.png"
              alt="Cancel"
              className={styles.imgCancelBtn}
              onClick={(event) => {
                event.stopPropagation();
                handleRemove(index);
              }}
            />
            {file.type.startsWith("image/") && (
              <>
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className={
                    viewFileStyle ? viewFileStyle : styles.fileImgVideo
                  }
                />
                {setMainPhoto && (
                  <div className={styles.mainPhotoToggle}>
                    <label
                      className="d-flex justify-content-center"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <input
                        className={styles.selectMainPhoto}
                        type="radio"
                        name="mainPhoto"
                        checked={mainPhoto === file}
                        onChange={(event) => handleMainPhotoChange(event, file)}
                      />
                      {mainPhoto === file ? (
                        <strong>
                          <p style={{ marginBottom: "0" }}>Головне фото</p>
                        </strong>
                      ) : (
                        <strong>
                          <p style={{ marginBottom: "0" }}>Зробити головним</p>
                        </strong>
                      )}
                    </label>
                  </div>
                )}
              </>
            )}
            {file.type.startsWith("video/") && (
              <video
                src={URL.createObjectURL(file)}
                alt={file.name}
                className={viewFileStyle ? viewFileStyle : styles.fileImgVideo}
                controls
              />
            )}
            {file.type === "application/pdf" && (
              <div>
                <img
                  src="/images/pdf.png"
                  alt="PDF Icon"
                  className={
                    viewFileStyle ? viewFileStyle : styles.fileImgVideo
                  }
                />
                <p style={{ marginBottom: "0px" }}>{file.name}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadArea;
