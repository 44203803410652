import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

const ProtectedRoute = ({ children, requiredRoles }) => {
  const { user, isAuthenticated, loading } = useAuth();

  const checkAccess = (requiredRoles) => {
    const hasAdminAccess = localStorage.getItem("hasAccessToAdminPages");
    const hasModeratorAccess = localStorage.getItem(
      "hasAccessToModeratorPages"
    );

    if (requiredRoles.includes("SuperAdmin") && hasAdminAccess) {
      return true;
    }
    if (requiredRoles.includes("Moderator") && hasModeratorAccess) {
      return true;
    }

    return false;
  };

  if (loading) {
    return null;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (requiredRoles && !checkAccess(requiredRoles)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
