import { format } from "date-fns";
import { uk } from "date-fns/locale";

/**
 * Форматує дату у вигляді "dd MMMM yyyy".
 * @param {string} dateString - Дата у вигляді рядка.
 * @returns {string} - Відформатована дата.
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "dd MMMM yyyy", { locale: uk });
};
