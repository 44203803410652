import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styles from "../ProfilePages/ProfileContent.module.css";

const ImagePreviewModal = ({ isOpen, image, toggle }) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    centered
    className={styles.modalImg}
    contentClassName={styles.modalContent}
  >
    <ModalHeader toggle={toggle}>Перегляд зображення</ModalHeader>
    <ModalBody className={styles.modalBody}>
      <img src={image} alt="Preview" className={styles.modalImgPrtview} />
    </ModalBody>
  </Modal>
);

export default ImagePreviewModal;
