import React, { useState, useEffect, useRef } from "react";
import Message from "./Message";
import Button from "./Button";
import styles from "./Chat.module.css";
import useSignalRForChat from "../../hooks/useSignalRForChat";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";

const Chat = ({ lotId, setMessageCount }) => {
  const { user, isAuthenticated } = useAuth();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [replyMessages, setReplyMessages] = useState({});
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const textareaRef = useRef(null);
  const replyTextareaRefs = useRef({});
  const connectionRef = useSignalRForChat(lotId, setMessages, setMessageCount);
  const messagesContainerRef = useRef(null);
  const bottomRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { fetchData: fetchChatMessageData } = useFetchData(
    `Chat/GetChatMessageLot/${lotId}`
  );
  const { fetchData: fetchMessageCountData } = useFetchData(
    `Chat/GetMessagesCount/${lotId}`
  );

  useEffect(() => {
    fetchChatMessageData()
      .then((data) => {
        setMessages(data);
        scrollToBottom();
      })
      .catch((err) => console.error("Error fetching messages:", err));
  }, [lotId, fetchChatMessageData]);

  useEffect(() => {
    fetchMessageCountData()
      .then((data) => {
        setMessageCount(data);
        scrollToBottom();
      })
      .catch((err) => console.error("Error fetching messages:", err));
  }, [lotId, fetchMessageCountData, setMessageCount]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      scrollToBottom();
    }
  }, [messages, shouldScrollToBottom]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const sendMessage = (userId, userName, userImg, message) => {
    connectionRef.current
      .invoke("SendMessage", {
        userId,
        lotId,
        text: message,
        messageId: null,
        userName,
        userImg,
      })
      .then(() => {
        setNewMessage("");
        setShouldScrollToBottom(true);
      })
      .catch((err) => console.error("Failed to send message:", err));
  };

  const sendComment = (parentId, newCommentText) => {
    if (newCommentText.trim() !== "") {
      const userId = user.id;
      const userName = user.username;
      const userImg = user.avatar !== "" ? user.avatar : "/images/profile1.png";
      const message = {
        userId,
        lotId,
        text: newCommentText,
        messageId: parentId,
        userName,
        userImg,
      };
      connectionRef.current
        .invoke("SendComment", message)
        .then(() => {
          setReplyMessages((prevReplyMessages) => ({
            ...prevReplyMessages,
            [parentId]: "",
          }));
          setMessages((prevMessages) =>
            prevMessages.map((message) =>
              message.id === parentId
                ? { ...message, showReplyInput: false }
                : message
            )
          );
          setShouldScrollToBottom(false);
        })
        .catch((err) => console.error("Failed to send comment:", err));
    }
  };

  const deleteMessage = (messageId) => {
    connectionRef.current
      .invoke("DeleteMessage", lotId, messageId)
      .then(() => setShouldScrollToBottom(false))
      .catch((err) => console.error("Failed to delete message:", err));
  };

  const deleteComment = (parentId, commentId) => {
    connectionRef.current
      .invoke("DeleteComment", parentId, commentId)
      .then(() => setShouldScrollToBottom(false))
      .catch((err) => console.error("Failed to delete comment:", err));
  };

  const handleReply = (parentId) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) =>
        message.id === parentId
          ? { ...message, showReplyInput: !message.showReplyInput }
          : message
      )
    );
    setShouldScrollToBottom(false);
  };

  const handleMessageSend = () => {
    if (isAuthenticated()) {
      if (newMessage.trim() !== "") {
        const userId = user.id;
        const userName = user.username;
        const userImg =
          user.avatar !== "" ? user.avatar : "/images/profile1.png";
        sendMessage(userId, userName, userImg, newMessage.trim());
        setShouldScrollToBottom(true);
      }
    } else {
      navigate("/login", { state: { from: location } });
    }
  };

  const handleReplySend = (parentId, newMessageText) => {
    if (newMessageText.trim() !== "") {
      sendComment(parentId, newMessageText.trim());
    }
  };

  const handleInput = (event) => {
    setNewMessage(event.target.value);
  };

  const handleReplyInputChange = (event, parentId) => {
    setReplyMessages((prevReplyMessages) => ({
      ...prevReplyMessages,
      [parentId]: event.target.value,
    }));
  };

  const adjustTextareaHeight = (ref) => {
    if (ref && ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight(textareaRef);
  }, [newMessage]);

  useEffect(() => {
    Object.values(replyTextareaRefs.current).forEach((ref) => {
      adjustTextareaHeight(ref);
    });
  }, [replyMessages]);

  const toggleReplies = (messageId, isComment) => {
    const updatedMessages = messages.map((message) => {
      if (message.id === messageId) {
        return {
          ...message,
          showReplies: !message.showReplies,
          comments: message.comments.map((comment) => ({
            ...comment,
            showReplies: isComment ? !comment.showReplies : false,
          })),
        };
      }
      return message;
    });
    setMessages(updatedMessages);
    setShouldScrollToBottom(false);
  };

  const renderMessages = () => (
    <div className={styles.messageList} ref={messagesContainerRef}>
      {messages.map((message) => (
        <Message
          key={message.id}
          message={message}
          deleteMessage={deleteMessage}
          handleReply={handleReply}
          toggleReplies={toggleReplies}
          handleReplySend={handleReplySend}
          handleReplyInputChange={handleReplyInputChange}
          replyMessages={replyMessages}
          replyTextareaRefs={replyTextareaRefs}
          deleteComment={deleteComment}
        />
      ))}
      <div ref={bottomRef} />
    </div>
  );

  const enterText = () => (
    <div className={styles.enterText}>
      <textarea
        ref={textareaRef}
        className={styles.textarea}
        value={newMessage}
        onChange={handleInput}
        rows={1}
        placeholder="Повідомлення..."
      />
      <Button onClick={handleMessageSend} className={styles.sendButton}>
        <img src="/images/Send.png" alt="send" />
      </Button>
    </div>
  );

  return (
    <div className={styles.chat}>
      {renderMessages()}
      {enterText()}
    </div>
  );
};

export default Chat;
