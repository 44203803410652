/**
 * Перетворює дані для опцій вибору в об'єкт, який використовують у селектах.
 * @param {Object} data - Вихідні дані з API для статусів файлів.
 * @returns {Array} - Массив об'єктів з value та label.
 */
export const transformSelectOption = (data) => {
  return Object.entries(data).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

/**
 * Перетворює об'єкт статусу в формат, який розуміють компоненти вибору.
 * @param {Object} statusData - Статус даних у форматі { key, value }.
 * @returns {Object} - Об'єкт зі значенням value та текстом label.
 */
export const transformOption = (statusData) => {
  return {
    value: statusData.key,
    label: statusData.value,
  };
};

/**
 * Формує дані для відправки на сервер під час модерації файлів.
 * @param {Array} filesStatus - Массив об'єктів зі статусами файлів.
 * @param {Object} user - Об'єкт користувача, який містить userId.
 * @param {Object} passportStatus - Статус паспорта, обраний у селекті.
 * @param {number} purpose - Ціль (прапорець) для відправки даних.
 * @returns {Object} - Об'єкт даних для відправки.
 */
export const prepareModerationData = (
  filesStatus,
  user,
  passportStatus,
  purpose
) => {
  return {
    PersonalFiles: filesStatus.map((fileStatus) => ({
      UserId: user.userId,
      MediaObjectId: fileStatus.fileId,
      Status: parseInt(fileStatus.status),
      FileRejectDescription: fileStatus.rejectReason,
    })),
    StatusVerificationFile: parseInt(passportStatus.value),
    Purpose: purpose,
  };
};
