import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import styles from "../UI/CarDeteils.module.css";
import Select from "react-select";
import ButtonSaveOrCancel from "../UI/ButtonSaveOrCancel";
import useFetchData from "../../hooks/useFetchData";

//Select custom style
const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "var(--dark-gray)" : "var(--dark-gray)",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--dark-gray)",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "15px",
    background: state.isSelected ? "var(--primary-blue)" : "var(--bs-white)",
    color: state.isSelected ? "white" : "var(--text-color)",
    "&:hover": {
      background: "#0097b26b",
      color: "white",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    minWidth: "100px",
    fontSize: "15px",
    background: "var(--bs-white)",
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: "var(--custom-gray)",
    },
  }),
};

const transformObject = (data) => {
  if (!data || typeof data !== "object") {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

const transformObjectModels = (data) => {
  if (!data || typeof data !== "object") {
    return {};
  }

  const brands = Object.keys(data);
  const transformedData = {};

  brands.forEach((brand) => {
    if (typeof data[brand] === "object") {
      transformedData[brand] = Object.entries(data[brand]).map(
        ([key, value]) => ({ value: key, label: value })
      );
    }
  });

  return transformedData;
};

const CarTableEdit = ({ car, onSaveChanges, onCancelEdit }) => {
  const [editedCar, setEditedCar] = useState({ ...car });
  const [bodyTypes, setBodyTypes] = useState([]);
  const [driveTypes, setDriveTypes] = useState([]);
  const [transmissionTypes, setTransmissionTypes] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [modelsByBrand, setModelsByBrand] = useState({});

  const { fetchData } = useFetchData("Filters/LotDropdownData");

  useEffect(() => {
    fetchData()
      .then((data) => {
        const transformedRegions = transformObject(data.regions);
        const transformedBodyTypes = transformObject(data.bodyTypes);
        const transformedDriveTypes = transformObject(data.drives);
        const transformedTransmissionTypes = transformObject(
          data.transmissions
        );
        const transformedFuelTypes = transformObject(data.fuels);
        const transformedModels = transformObjectModels(data.modelsByBrand);
        setBodyTypes(transformedBodyTypes);
        setDriveTypes(transformedDriveTypes);
        setTransmissionTypes(transformedTransmissionTypes);
        setFuelTypes(transformedFuelTypes);
        setRegions(transformedRegions);
        setBrands(data.brands);
        setModelsByBrand(transformedModels);
      })
      .catch((err) =>
        console.error("There was a problem fetching the car data:", err)
      );
  }, [fetchData]);

  const handleInputChange = (e, key) => {
    setEditedCar({ ...editedCar, [key]: e.target.value });
  };

  const handleSelectChange = (selectedOption, key) => {
    setEditedCar({
      ...editedCar,
      [key]: { key: selectedOption.value, value: selectedOption.label },
    });
  };

  const handleBrandChange = (selectedOption) => {
    const selectedBrandKey = selectedOption.value;
    const selectedBrand = {
      key: selectedBrandKey,
      value: selectedOption.label,
    };
    setEditedCar({
      ...editedCar,
      brand: selectedBrand,
      model: "",
    });
  };

  const handleModelChange = (selectedOption) => {
    setEditedCar({
      ...editedCar,
      model: { key: selectedOption.value, value: selectedOption.label },
    });
  };

  const handleSaveChanges = () => {
    onSaveChanges(editedCar);
  };

  const handleCancelChanges = () => {
    onCancelEdit();
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    if (
      isNaN(parseInt(key)) &&
      key !== "Backspace" &&
      key !== "Delete" &&
      !(key >= "0" && key <= "9")
    ) {
      event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className={`col-xxl-8 mb-4 ${styles.twoColumns}`}>
        <div className={styles.column}>
          <table className={styles.tableCar}>
            <tbody>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>Марка</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Select
                    options={brands}
                    value={brands.find(
                      (brand) => brand.value === editedCar.brand.key
                    )}
                    onChange={handleBrandChange}
                    styles={{
                      ...customStyles,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>Модель</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Select
                    options={
                      modelsByBrand[editedCar.brand.key.toUpperCase()] || []
                    }
                    value={modelsByBrand[
                      editedCar.brand.key.toUpperCase()
                    ]?.find(
                      (model) =>
                        model.value === editedCar.model.key.toUpperCase()
                    )}
                    onChange={handleModelChange}
                    isDisabled={!editedCar.brand}
                    styles={{
                      ...customStyles,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>Пробіг</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Input
                    type="text"
                    value={editedCar.mileage}
                    onChange={(e) => handleInputChange(e, "mileage")}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    className={styles.input}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>VIN</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  {car.vin}
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>Власник</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Input
                    type="text"
                    value={editedCar.owner}
                    onChange={(e) => handleInputChange(e, "owner")}
                    className={styles.input}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>Місто</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Select
                    options={regions}
                    value={{
                      label: editedCar.region.value,
                      value: editedCar.region.key.toString(),
                    }}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "region")
                    }
                    styles={{
                      ...customStyles,
                    }}
                    menuPlacement="top"
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>Продавець</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href={car.ownerID ? `Profile?userId=${car.ownerID}` : "#"}>
                    <img
                      alt="profile"
                      src="/images/profile1.png"
                      style={{ width: "25px" }}
                    />
                    {car.ownerName}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.column}>
          <table className={styles.tableCar}>
            <tbody>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>Двигун/Паливо</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <div className="d-flex">
                    <Input
                      type="text"
                      value={editedCar.engine}
                      onChange={(e) => handleInputChange(e, "engine")}
                      style={{ width: "50%", marginRight: "5px" }}
                      className={styles.input}
                    />
                    <div style={{ width: "50%" }}>
                      <Select
                        options={fuelTypes}
                        value={{
                          label: editedCar.fuelType.value,
                          value: editedCar.fuelType.key.toString(),
                        }}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, "fuelType")
                        }
                        styles={{
                          ...customStyles,
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>Привід</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Select
                    options={driveTypes}
                    value={{
                      label: editedCar.drive.value,
                      value: editedCar.drive.key.toString(),
                    }}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "drive")
                    }
                    styles={{
                      ...customStyles,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>КПП</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Select
                    options={transmissionTypes}
                    value={{
                      label: editedCar.gearbox.value,
                      value: editedCar.gearbox.key.toString(),
                    }}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "gearbox")
                    }
                    styles={{
                      ...customStyles,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>Кузов</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Select
                    options={bodyTypes}
                    value={{
                      label: editedCar.body.value,
                      value: editedCar.body.key.toString(),
                    }}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "body")
                    }
                    styles={{
                      ...customStyles,
                    }}
                    menuShouldScrollIntoView={true}
                    maxMenuHeight={160}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>Колір</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Input
                    type="text"
                    value={editedCar.color}
                    onChange={(e) => handleInputChange(e, "color")}
                    className={styles.input}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>Колір салону</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Input
                    type="text"
                    value={editedCar.interiorColor}
                    onChange={(e) => handleInputChange(e, "interiorColor")}
                    className={styles.input}
                  />
                </td>
              </tr>
              <tr>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>Тип продавця</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  <Input
                    type="text"
                    value={editedCar.sellerType}
                    onChange={(e) => handleInputChange(e, "sellerType")}
                    className={styles.input}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <ButtonSaveOrCancel
          handelSave={handleSaveChanges}
          handelCencel={handleCancelChanges}
        />
      </div>
    </div>
  );
};

export default CarTableEdit;
