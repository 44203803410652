import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import styles from "./UserFilesModal.module.css";

const CustomPagination = ({ currentPage, pageCount, paginate }) => {
  return (
    pageCount > 1 && (
      <Pagination
        aria-label="Page navigation example"
        size="sm"
        className="d-flex justify-content-center align-items-center"
      >
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => paginate(currentPage - 1)}
            className={styles.customPaginationLink}
          />
        </PaginationItem>

        {[...Array(pageCount)].map((_, index) => (
          <PaginationItem key={index} active={currentPage === index + 1}>
            <PaginationLink
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1
                  ? styles.activePage
                  : styles.customPaginationLink
              }`}
            >
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem disabled={currentPage === pageCount}>
          <PaginationLink
            next
            onClick={() => paginate(currentPage + 1)}
            className={styles.customPaginationLink}
          />
        </PaginationItem>
      </Pagination>
    )
  );
};

export default CustomPagination;
