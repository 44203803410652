import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import TextBlock from "../UI/TextBlock";
import TodoList from "../UI/TodoList";
import Question from "../UI/Question";
import styles from "./ExhibitingACar.module.css";
import UploadArea from "../UI/UploadArea";
import InputFormGroup from "../UI/InputFormGroup";
import SelectFormGroup from "../UI/SelectFormGroup";
import ScrollArrow from "../UI/ScrollArrow";
import { useToast } from "../../contexts/ToastContext";
import useFetchData from "../../hooks/useFetchData";
import RequestType from "../../models/RequestType";
import { useAuth } from "../../contexts/AuthContext";

const transformObject = (data) => {
  if (!data || typeof data !== "object") {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

const transformObjectModels = (data) => {
  if (!data || typeof data !== "object") {
    return {};
  }

  const brands = Object.keys(data);
  const transformedData = {};

  brands.forEach((brand) => {
    if (typeof data[brand] === "object") {
      transformedData[brand] = Object.entries(data[brand]).map(
        ([key, value]) => ({ value: key, label: value })
      );
    }
  });

  return transformedData;
};

const ExhibitingACar = () => {
  const [inputVIN, setInputVIN] = useState("");
  const [inputNumber, setInputNumber] = useState("");
  const [inputMileage, setInputMileage] = useState("");
  const [inputEngine, setInputEngine] = useState("");
  const [inputCarColor, setInputCarColor] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedBodyType, setSelectedBodyType] = useState(null);
  const [selectedDrive, setSelectedDrive] = useState(null);
  const [selectedTransmission, setSelectedTransmission] = useState(null);
  const [selectedFuel, setSelectedFuel] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [models, setModels] = useState([]);
  const [carDescription, setCarDescription] = useState("");
  const [inputReservePrice, setInputReservePrice] = useState("");
  const [completeSetOfCars, setCompleteSetOfCars] = useState([""]);
  const [modifiedList, setModifiedList] = useState([""]);
  const [disadvantagesList, setDisadvantagesList] = useState([""]);
  const [serviceHistoryDescription, setServiceHistoryDescription] =
    useState("");
  const [filesServiceHistory, setFilesServiceHistory] = useState([]);
  const [files, setFiles] = useState([]);
  const [mainPhoto, setMainPhoto] = useState(undefined);

  const [modified, setModified] = useState(false);
  const [disadvantages, setDisadvantages] = useState(false);
  const [reservePrice, setReservePrice] = useState(false);

  const { showToast } = useToast();

  const [showUpArrow, setShowUpArrow] = useState(false);
  const [showDownArrow, setShowDownArrow] = useState(true);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { user } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      setShowUpArrow(scrollTop > clientHeight);
      setShowDownArrow(scrollHeight - (scrollTop + clientHeight) > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  //Данні які приходять щоб встановити в селекти
  const [years, setYears] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [drives, setDrives] = useState([]);
  const [transmissions, setTransmissions] = useState([]);
  const [fuels, setFuels] = useState([]);
  const [regions, setRegions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [modelsByBrand, setModelsByBrand] = useState({});

  const { fetchData: fetchFiltersData } = useFetchData(
    "Filters/LotDropdownData"
  );

  const { fetchData: fetchLotData } = useFetchData(
    "Lot/PostLot",
    RequestType.POST
  );

  useEffect(() => {
    fetchFiltersData()
      .then((data) => {
        setYears(data.years);
        setBodyTypes(transformObject(data.bodyTypes));
        setDrives(transformObject(data.drives));
        setTransmissions(transformObject(data.transmissions));
        setFuels(transformObject(data.fuels));
        setRegions(transformObject(data.regions));
        setBrands(data.brands);
        setModelsByBrand(transformObjectModels(data.modelsByBrand));
      })
      .catch((err) =>
        console.error("There was a problem fetching the car data:", err)
      );
  }, [fetchFiltersData]);

  const handleSelectChange = (setter) => (selectedOption) => {
    setter(selectedOption);
  };

  const handleBrandChange = (selectedBrand) => {
    setSelectedBrand(selectedBrand);
    setSelectedModel(null);
    setModels(modelsByBrand[selectedBrand.value]);
  };

  const handleDescriptionChange = (event) => {
    setCarDescription(event.target.value);
  };

  const handleServiceHistoryDescChange = (event) => {
    setServiceHistoryDescription(event.target.value);
  };

  useEffect(() => {
    if (!reservePrice) {
      setInputReservePrice("");
    }
  }, [reservePrice]);

  const handleReservePriceChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setInputReservePrice(value);
    }
  };

  const handleModifiedChange = (value) => {
    setModified(value);
    if (!value) {
      setModifiedList([""]);
    }
  };

  const handleВisadvantagesChange = (value) => {
    setDisadvantages(value);
    if (!value) {
      setDisadvantagesList([""]);
    }
  };

  const isNumeric = (value) => {
    return /^[0-9]*$/.test(value);
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\D/g, "");
    return inputValue;
  };

  useEffect(() => {
    const textarea = document.getElementById("carDescriptionTextarea");
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }, [carDescription]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!mainPhoto) {
      showToast(
        "Помилка",
        "Будь ласка, завантажте головне фото авто.",
        "warning"
      );
      return;
    }

    setFormSubmitted(true);

    if (validateForm()) {
      const formData = new FormData();
      formData.append("OwnerID", user?.id);
      formData.append("VIN", inputVIN);
      formData.append("RegistrationCarNumber", inputNumber);
      formData.append("ProductionYear", parseInt(selectedYear.value));
      formData.append("Brand", selectedBrand.label);
      formData.append("Model", selectedModel.label);
      formData.append("Mileage", parseInt(inputMileage));
      formData.append("Body", selectedBodyType.value);
      formData.append("Drive", selectedDrive.value);
      formData.append("Engine", inputEngine);
      formData.append("Gearbox", selectedTransmission.value);
      formData.append("FuelType", selectedFuel.value);
      formData.append("Color", inputCarColor);
      formData.append("Region", selectedRegion.value);
      formData.append("CarDescription", carDescription);
      formData.append("ServiceHistoryDescription", serviceHistoryDescription);
      formData.append(
        "ReservePrice",
        isNaN(parseInt(inputReservePrice)) ? null : parseInt(inputReservePrice)
      );
      formData.append("HasReservePrice", reservePrice);
      completeSetOfCars
        .filter((car) => car.trim() !== "")
        .forEach((car, index) => {
          formData.append(`CompleteSetOfCars[${index}]`, car);
        });
      modifiedList
        .filter((item) => item.trim() !== "")
        .forEach((item, index) => {
          formData.append(`ModifiedList[${index}]`, item);
        });
      disadvantagesList
        .filter((item) => item.trim() !== "")
        .forEach((item, index) => {
          formData.append(`DisadvantagesList[${index}]`, item);
        });
      formData.append("MainPhoto", mainPhoto);
      filesServiceHistory.forEach((file) => {
        formData.append("FilesServiceHistory", file);
      });
      files
        .filter((file) => file !== mainPhoto)
        .forEach((file) => {
          formData.append("Files", file);
        });

      fetchLotData(formData)
        .then(() => {
          showToast("Успіх", "Форма успішно відправлена", "success");
          resetForm();
        })
        .catch((err) =>
          showToast(
            "Помилка",
            `Помилка при відправленні форми: ${err}`,
            "danger"
          )
        );
    } else {
      showToast(
        "Помилка",
        "Будь ласка, заповніть всі обов'язкові поля форми.",
        "warning"
      );
      const carInfoSection = document.querySelector("#formCar");
      const offset = carInfoSection.offsetTop - 120;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    errors.inputVIN = !inputVIN ? "Поле VIN є обов'язковим." : "";
    errors.inputNumber = !inputNumber ? "Поле Номер обов'язкове." : "";
    errors.selectedYear =
      !selectedYear || !selectedYear.value ? "Поле 'Рік' є обов'язковим." : "";
    errors.selectedBrand =
      !selectedBrand || !selectedBrand.value
        ? "Поле 'Бренд' є обов'язковим."
        : "";
    errors.selectedModel =
      !selectedModel || !selectedModel.value
        ? "Поле 'Модель' є обов'язковим."
        : "";
    errors.inputMileage = !inputMileage ? "Поле Пробіг, км обов'язкове." : "";
    errors.selectedBodyType =
      !selectedBodyType || !selectedBodyType.value
        ? "Поле Кузов обов'язкове."
        : "";
    errors.selectedDrive =
      !selectedDrive || !selectedDrive.value ? "Поле Привід обов'язкове." : "";
    errors.inputEngine = !inputEngine ? "Поле Двигун обов'язкове." : "";
    errors.selectedTransmission =
      !selectedTransmission || !selectedTransmission.value
        ? "Поле КПП обов'язкове."
        : "";
    errors.selectedFuel =
      !selectedFuel || !selectedFuel.value
        ? "Поле Тип палива обов'язкове."
        : "";
    errors.inputCarColor = !inputCarColor ? "Поле Колір авто обов'язкове." : "";
    errors.selectedRegion =
      !selectedRegion || !selectedRegion.value
        ? "Поле Область обов'язкове."
        : "";

    setFormErrors(errors);

    return Object.values(errors).every((error) => error === "");
  };

  const resetForm = () => {
    setInputVIN("");
    setInputNumber("");
    setSelectedYear(null);
    setSelectedBrand(null);
    setSelectedModel(null);
    setInputMileage("");
    setSelectedBodyType(null);
    setSelectedDrive(null);
    setInputEngine("");
    setSelectedTransmission(null);
    setSelectedFuel(null);
    setInputCarColor("");
    setSelectedRegion(null);
    setCarDescription("");
    setServiceHistoryDescription("");
    setInputReservePrice("");
    setCompleteSetOfCars([""]);
    setModifiedList([""]);
    setDisadvantagesList([""]);
    setFiles([]);
    setMainPhoto(undefined);
    setModified(false);
    setDisadvantages(false);
    setReservePrice(false);
    setFormErrors({});
  };

  //хендлер щоб неможна булло вводити нічого окрім цифр
  const handleKeyDown = (event) => {
    const key = event.key;
    if (
      isNaN(parseInt(key)) &&
      key !== "Backspace" &&
      key !== "Delete" &&
      !(key >= "0" && key <= "9")
    ) {
      event.preventDefault();
    }
  };
  //щоб неможна було вставити нічого окрім цифр
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fullName =
    user?.firstName || user?.surname
      ? `${user?.firstName || ""} ${user?.surname || ""}`.trim()
      : "N/A";

  const phoneNum = user?.phoneNumber ? `${user?.phoneNumber}` : "N/A";

  return (
    <div className={styles.mwDiv}>
      <h1 className={styles.mainTitle}>
        <strong>Розкажіть нам про своє авто</strong>
      </h1>
      <TextBlock color="#dbf6fa" className="cardText">
        <h4 className="mb-4">
          <strong>Інформація про Вас</strong>
        </h4>
        <div className="row">
          <div className="col-xl-6">
            <Label className={styles.labelExhibitingACar}>Повне ім’я</Label>
            <p className={styles.pTextInform}>{fullName}</p>
          </div>
          <div className="col-xl-6">
            <Label className={styles.labelExhibitingACar}>Номер телефону</Label>
            <p className={styles.pTextInform}>{phoneNum}</p>
          </div>
        </div>
      </TextBlock>
      <Form onSubmit={handleSubmit}>
        <TextBlock color="#fff" className="cardText">
          <h4 className="mb-4" id="formCar">
            <strong>Інформація про авто </strong>
          </h4>
          <div className="row mb-3">
            <div className="col-xl-6">
              <InputFormGroup
                label="VIN"
                errorMessage={formErrors.inputVIN}
                value={inputVIN}
                onChange={handleSelectChange(setInputVIN)}
                formSubmitted={formSubmitted}
              />
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-6">
                  <InputFormGroup
                    label="Номер"
                    errorMessage={formErrors.inputNumber}
                    value={inputNumber}
                    onChange={handleSelectChange(setInputNumber)}
                    formSubmitted={formSubmitted}
                  />
                </div>
                <div className="col-xl-6">
                  <SelectFormGroup
                    label="Рік"
                    value={selectedYear}
                    onChange={handleSelectChange(setSelectedYear)}
                    options={years}
                    isSearchable={true}
                    placeholder="Обрати"
                    onInputChange={handleInputChange}
                    formatOptionLabel={({ label }) =>
                      isNumeric(label) ? label : ""
                    }
                    formSubmitted={formSubmitted}
                    errorMessage={formErrors.selectedYear}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-xl-6">
              <SelectFormGroup
                label="Марка"
                value={selectedBrand}
                onChange={handleBrandChange}
                options={brands}
                isSearchable={true}
                placeholder="Обрати марку"
                noOptionsMessage={() => "Такої марки нема"}
                errorMessage={formErrors.selectedBrand}
                formSubmitted={formSubmitted}
              />
            </div>
            <div className="col-xl-6">
              <SelectFormGroup
                label="Модель"
                value={selectedModel}
                onChange={handleSelectChange(setSelectedModel)}
                options={models}
                isSearchable={true}
                placeholder="Обрати модель"
                noOptionsMessage={() => "Спочатку оберіть марку"}
                errorMessage={formErrors.selectedModel}
                formSubmitted={formSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-xl-3">
              <InputFormGroup
                label="Пробіг, км"
                errorMessage={formErrors.inputMileage}
                value={inputMileage}
                onChange={handleSelectChange(setInputMileage)}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                formSubmitted={formSubmitted}
              />
            </div>
            <div className="col-xl-5">
              <SelectFormGroup
                label="Кузов"
                value={selectedBodyType}
                onChange={handleSelectChange(setSelectedBodyType)}
                options={bodyTypes}
                isSearchable={true}
                placeholder="Обрати тип кузова"
                errorMessage={formErrors.selectedBodyType}
                formSubmitted={formSubmitted}
              />
            </div>
            <div className="col-xl-4">
              <SelectFormGroup
                label="Привід"
                value={selectedDrive}
                onChange={handleSelectChange(setSelectedDrive)}
                options={drives}
                isSearchable={true}
                placeholder="Обрати привід"
                errorMessage={formErrors.selectedDrive}
                formSubmitted={formSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-xl-4">
              <InputFormGroup
                label="Двигун"
                errorMessage={formErrors.inputEngine}
                value={inputEngine}
                onChange={handleSelectChange(setInputEngine)}
                formSubmitted={formSubmitted}
              />
            </div>
            <div className="col-xl-3">
              <SelectFormGroup
                label="КПП"
                value={selectedTransmission}
                onChange={handleSelectChange(setSelectedTransmission)}
                options={transmissions}
                isSearchable={true}
                placeholder="Обрати тип КПП"
                errorMessage={formErrors.selectedTransmission}
                formSubmitted={formSubmitted}
              />
            </div>
            <div className="col-xl-5">
              <SelectFormGroup
                label="Тип палива"
                value={selectedFuel}
                onChange={handleSelectChange(setSelectedFuel)}
                options={fuels}
                isSearchable={true}
                placeholder="Обрати тип палива"
                errorMessage={formErrors.selectedFuel}
                formSubmitted={formSubmitted}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-xl-6">
              <InputFormGroup
                label="Колір авто"
                errorMessage={formErrors.inputCarColor}
                value={inputCarColor}
                onChange={handleSelectChange(setInputCarColor)}
                formSubmitted={formSubmitted}
              />
            </div>
            <div className="col-xl-6">
              <SelectFormGroup
                label="Область"
                value={selectedRegion}
                onChange={handleSelectChange(setSelectedRegion)}
                options={regions}
                isSearchable={true}
                placeholder="Обрати область"
                errorMessage={formErrors.selectedRegion}
                formSubmitted={formSubmitted}
              />
            </div>
          </div>
          <div className="row mb-4">
            <FormGroup>
              <Label className={styles.labelExhibitingACar}>Опис авто</Label>
              <textarea
                id="carDescriptionTextarea"
                value={carDescription}
                onChange={handleDescriptionChange}
                className={styles.textArea}
              />
            </FormGroup>
          </div>
          <div className="row mb-4">
            <Question
              id="question1"
              title="Комплектація авто"
              hint="Вкажіть, які комплектації має авто. Це можуть бути опції та додаткові функції, які забезпечуються у встановленому автомобілі, такі як системи безпеки, розваг та інші характеристики."
            />
            <FormGroup>
              <TodoList
                placeholder="Додати ще..."
                todos={completeSetOfCars}
                setTodos={setCompleteSetOfCars}
              />
            </FormGroup>
          </div>
          <div className="row mb-4">
            <Question
              id="question2"
              title="Чи було авто модифіковано?"
              hint="Вкажіть, чи в автомобілі були внесені будь-які зміни або модифікації, такі як зміна компонентів двигуна, зовнішні зміни, покращення в салоні або будь-які інші зміни, які впливають на його вигляд або функціональність."
              stateElement={modified}
              setStateElement={handleModifiedChange}
            />
            {modified && (
              <>
                <FormGroup>
                  <Label className={styles.labelExhibitingACar}>
                    Які модифікації має авто?
                  </Label>
                  <TodoList
                    placeholder="Додати ще  модифікацію..."
                    todos={modifiedList}
                    setTodos={setModifiedList}
                  />
                </FormGroup>
              </>
            )}
          </div>
          <div className="row mb-4">
            <Question
              id="question3"
              title="Авто має недоліки?"
              hint="Вкажіть про будь-які проблеми або недоліки, які можуть виникнути при користуванні автомобілем. Це можуть бути технічні або косметичні проблеми, які впливають на його функціональність або зовнішній вигляд."
              stateElement={disadvantages}
              setStateElement={handleВisadvantagesChange}
            />
            {disadvantages && (
              <>
                <FormGroup>
                  <Label className={styles.labelExhibitingACar}>
                    Які недоліки має авто?
                  </Label>
                  <TodoList
                    placeholder="Додати ще недолік..."
                    todos={disadvantagesList}
                    setTodos={setDisadvantagesList}
                  />
                </FormGroup>
              </>
            )}
          </div>
        </TextBlock>
        <TextBlock color="#dbf6fa" className="cardText">
          <h4 className="mb-4">
            <strong>Резервна ціна</strong>
          </h4>
          <Question
            id="question4"
            title="Чи бажаєте ви встановити резервну ціну?"
            hint="Резервна ціна - це сума, за яку ви готові продати автомобіль, якщо його основна ціна не буде досягнута на аукціоні. Врахуйте ринкову ціну авто, його стан та інші фактори при встановленні резервної ціни."
            stateElement={reservePrice}
            setStateElement={setReservePrice}
          />
          <TextBlock color="#fff" className={styles.cardTextPrice}>
            <p>
              Резервна ціна - це секретна, мінімальна ціна, необхідна для
              продажу вашого автомобіля. Автомобілі з резервною ціною можуть
              викликати менший інтерес, ніж ті, що без неї.
            </p>
            <p>
              Зверніть увагу, що в результаті торгів ціна часто буває значно
              вищою за резервну. Для отримання додаткової інформації
            </p>
          </TextBlock>
          {reservePrice && (
            <div className="col-xl-6">
              <FormGroup>
                <Label className={styles.labelExhibitingACar}>
                  Встановіть резервну ціну, яку б ви хотіли(USD)
                </Label>
                <InputGroup>
                  <InputGroupText className={styles.inputGroupText}>
                    $
                  </InputGroupText>
                  <Input
                    className={styles.inputGroup}
                    value={inputReservePrice}
                    onChange={handleReservePriceChange}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                  />
                </InputGroup>
              </FormGroup>
            </div>
          )}
        </TextBlock>
        <TextBlock color="#fff" className="cardText">
          <h4 className="mb-4">
            <strong>Сервісна історія</strong>
          </h4>
          <FormGroup>
            <Label className={styles.labelExhibitingACar}>
              Опис сервісної історії
            </Label>
            <textarea
              id="ServiceHistoryTextarea"
              value={serviceHistoryDescription}
              onChange={handleServiceHistoryDescChange}
              className={styles.textArea}
            />
          </FormGroup>
          <FormGroup>
            <Label className={styles.labelExhibitingACar}>
              Будь ласка, завантажте сюди фотографії Сервісної історії
              автомобіля
            </Label>
            <UploadArea
              files={filesServiceHistory}
              setFiles={setFilesServiceHistory}
              id="fileServiceHistory"
            />
          </FormGroup>
        </TextBlock>
        <TextBlock color="#dbf6fa" className="cardText">
          <h4 className="mb-4">
            <strong>Фотографії авто</strong>
          </h4>
          <FormGroup>
            <Label className={styles.labelExhibitingACar}>
              Будь ласка, завантажте сюди фотографії Вашого авто{" "}
              <span className={styles.spanStar}>*</span>
            </Label>
            <UploadArea
              id="fileImages"
              files={files}
              setFiles={setFiles}
              mainPhoto={mainPhoto}
              setMainPhoto={setMainPhoto}
            />
          </FormGroup>
          <button type="submit" className={styles.btnFormSubmit}>
            <strong>Підтвердити</strong>
          </button>
        </TextBlock>
      </Form>
      <div className={styles.arrowScroll}>
        {showUpArrow && (
          <ScrollArrow
            arrowClass={styles.arrowUp}
            scroll={scrollUp}
            up={true}
          />
        )}
        {showDownArrow && (
          <ScrollArrow arrowClass={styles.arrowDown} scroll={scrollDown} />
        )}
      </div>
    </div>
  );
};

export default ExhibitingACar;
