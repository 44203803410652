import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Outlet } from "react-router-dom";
import NavMenu from "./Layout/NavMenu";
import Footer from "./Layout/Footer";
import { useAuth } from "../contexts/AuthContext";
import { useToast } from "../contexts/ToastContext";
import useSignalRForPersonalizedMessage from "../hooks/useSignalRForPersonalizedMessage";
import UniversalToast from "./UI/UniversalToast";
import styles from "./Layout.module.css";
import { SignalRUserProvider } from "../contexts/SignalRUserContext";

const Layout = () => {
  const [toastMessageBody, setToastMessageBody] = useState("");
  const [notification, setNotification] = useState({});
  const { user } = useAuth();
  const { toasts, showToast, handleToastClick } = useToast();
  const connectionRef = useSignalRForPersonalizedMessage(
    user?.id,
    setToastMessageBody,
    setNotification
  );

  useEffect(() => {
    if (toastMessageBody) {
      showToast(toastMessageBody, "", "primary");
      setToastMessageBody("");
    }
  }, [toastMessageBody, showToast]);

  const toastMassege = () => (
    <div className={styles.toastContainer}>
      {toasts.map((toast) => (
        <UniversalToast
          className={styles.toast}
          key={toast.id}
          id={toast.id}
          isOpen={true}
          header={toast.header}
          body={toast.body}
          icon={toast.type}
          toggle={() => handleToastClick(toast.id)}
        />
      ))}
    </div>
  );

  return (
    <SignalRUserProvider
      connection={connectionRef.current}
      newNotifiation={notification}
    >
      <div
        className="d-flex flex-column min-vh-100"
        style={{ background: "#f9f9f9" }}
      >
        <NavMenu />
        <Container className="flex-grow-1" tag="main">
          <Outlet />
        </Container>
        <Footer className="flex-shrink-0" />
        {toastMassege()}
      </div>
    </SignalRUserProvider>
  );
};

export default Layout;
