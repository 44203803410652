import { useCallback } from "react";
import AxiosInstance from "../models/AxiosInstance";
import RequestType from "../models/RequestType";

const useFetchData = (url, requestType = RequestType.GET) => {
  const fetchData = useCallback(
    async (data = null) => {
      return new Promise(async (resolve, reject) => {
        if (!url) return;
        try {
          let response;
          const requestData = data;

          switch (requestType) {
            case RequestType.GET:
              response = await AxiosInstance.get(url, {
                withCredentials: true,
              });
              break;
            case RequestType.POST:
              response = await AxiosInstance.post(url, requestData, {
                headers: {
                  "Content-Type":
                    requestData instanceof FormData
                      ? "multipart/form-data"
                      : "application/json",
                },
                withCredentials: true,
              });
              break;
            case RequestType.PUT:
              response = await AxiosInstance.put(url, requestData, {
                withCredentials: true,
              });
              break;
            case RequestType.DELETE:
              response = await AxiosInstance.delete(url, {
                withCredentials: true,
              });
              break;
            default:
              throw new Error("Invalid request type");
          }

          if (response.status !== 200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          resolve(response.data);
        } catch (err) {
          const errorMessage =
            err.response?.data?.message || err.message || "An error occurred";
          reject(errorMessage);
        }
      });
    },
    [url, requestType]
  );

  return { fetchData };
};

export default useFetchData;
