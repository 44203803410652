import React, { useState, useEffect } from "react";
import { FormGroup, Input } from "reactstrap";
import styles from "../ProfilePages/ProfileContent.module.css";
import { useToast } from "../../contexts/ToastContext";
import useFetchData from "../../hooks/useFetchData";
import RequestType from "../../models/RequestType";

const FormRowSwitchesSetings = ({ label, type, value, userId }) => {
  const [checked, setChecked] = useState(value);
  const { showToast } = useToast();
  const { fetchData } = useFetchData(
    "Profile/UserProfileSetings",
    RequestType.POST
  );

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleSwitchChange = async () => {
    const newChecked = !checked;
    setChecked(newChecked);

    fetchData({
      userId: userId,
      setting: type,
      settingLabel: label,
      value: newChecked,
    })
      .then((data) =>
        showToast(
          "Успіх",
          `Налаштування ${data.message} оновленні: Успішно`,
          "success"
        )
      )
      .catch((err) => {
        showToast(
          "Помилка",
          `Виникла помилка зміни налаштувань: ${err}`,
          "danger"
        );
        setChecked(checked);
      });
  };

  return (
    <div className="row mb-4">
      <div className="col-sm-8">
        <div className="form-group">
          <label className={styles.lableSetings}>{label}</label>
        </div>
      </div>
      <div className="col-sm-4 d-flex justify-content-end align-items-center">
        <FormGroup switch>
          <Input
            type="switch"
            checked={checked}
            onChange={handleSwitchChange}
            className={`${styles.customInputSetings}`}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default FormRowSwitchesSetings;
